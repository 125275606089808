// --------- CORE ------------

import { emojis } from '@officely/emoji';
import { ManagedNotificationType } from './types';

export enum Product {
  Officely = 'officely',
  Parkly = 'parkly',
}

export const YYYY_MM_DD = 'YYYY-MM-DD';

export enum Weekday {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export enum UserWorkStatusType {
  OFFICE = 'office',
  REMOTE = 'remote',
  NON_WORKING_DAY = 'nonWorkingDay',
  OFF_SICK = 'offSick',
  ANNUAL_LEAVE = 'annualLeave',
  OTHER_ABSENCE = 'otherAbsence',
  NO_STATUS = 'noStatus',
  WORK_TRAVEL = 'workTravel',
  CLIENT_OFFICE = 'clientOffice',
}

export const HRIS_STATUSES = [
  UserWorkStatusType.OFF_SICK,
  UserWorkStatusType.ANNUAL_LEAVE,
  UserWorkStatusType.WORK_TRAVEL,
  UserWorkStatusType.OTHER_ABSENCE,
] as const;

export const NOTE_MAX_CHARACTERS = 50;

export const NON_OFFICE_STATUSES = Object.values(UserWorkStatusType).filter(
  (t) => t !== UserWorkStatusType.OFFICE
) as Exclude<UserWorkStatusType, UserWorkStatusType.OFFICE>[];

export const DAILY_STATUSES = [
  UserWorkStatusType.OFFICE,
  UserWorkStatusType.REMOTE,
  UserWorkStatusType.CLIENT_OFFICE,
  UserWorkStatusType.NON_WORKING_DAY,
];

export const DAILY_NON_OFFICE_STATUSES: UserWorkStatusType[] = DAILY_STATUSES.filter(
  (s) => s !== UserWorkStatusType.OFFICE
);

export const AWAY_DATE_STATUSES = [
  UserWorkStatusType.OFF_SICK,
  UserWorkStatusType.ANNUAL_LEAVE,
  UserWorkStatusType.WORK_TRAVEL,
];

export const AWAY_DATES_DESCRIPTION_MAPPING = {
  [UserWorkStatusType.ANNUAL_LEAVE]: {
    description: 'vacation dates',
    cancelText: 'Cancel vacation',
    noteText: 'Going anywhere nice?',
  },
  [UserWorkStatusType.WORK_TRAVEL]: {
    description: 'work travel dates',
    cancelText: 'Cancel work travel',
    noteText: 'Keep your team in the loop',
  },
  [UserWorkStatusType.OFF_SICK]: {
    description: 'sick days',
    cancelText: 'Cancel sick days',
    noteText: 'Add a note',
  },
  [UserWorkStatusType.OTHER_ABSENCE]: {
    description: 'other absences',
    cancelText: 'Cancel absences',
    noteText: 'Add a note',
  },
};

export enum TimeSlot {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  ALL_DAY = 'all_day',
}

export enum BookingMethod {
  APP_HOME = 'app_home',
  OFFICELY_FM = 'officely_fm',
  SLASH_COMMAND = 'slash_command',
  VIEW_DAY = 'view_day',
  COWORKER_DIGEST = 'coworker_digest',
  OFFICE_DAY_INVITE = 'office_day_invite',
  MANAGER = 'manager',
  OFFICE_CLOSURE = 'office_closure',
  TEAM_RECURRING_BOOKING = 'team_recurring_booking',
  HRIS = 'hris',
  OFFICELY_ADMIN = 'officely_admin',
  ROUTINE = 'routine',
  WAITLIST = 'waitlist',
  WEEKLY_REMINDER = 'weekly_reminder',
  CHECK_IN = 'check_in',
  STATUS_REMINDER = 'status_reminder',
  SMART_NOTIFICATION = 'smart_notification',
}

export enum UserWorkStatusCategory {
  POPULAR = 'Everyday',
  TRAVEL = 'Roaming',
  NON_WORKING = 'Off',
}

export const USER_WORK_STATUS_PROFILES = {
  [UserWorkStatusType.OFFICE]: {
    emoji: emojis.office,
    name: 'Office',
    isWorking: true,
    category: UserWorkStatusCategory.POPULAR,
  },
  [UserWorkStatusType.REMOTE]: {
    emoji: emojis.remote,
    name: 'Home',
    isWorking: true,
    category: UserWorkStatusCategory.POPULAR,
  },
  [UserWorkStatusType.NON_WORKING_DAY]: {
    emoji: emojis.not_working,
    name: 'Non-working day',
    isWorking: false,
    category: UserWorkStatusCategory.NON_WORKING,
  },
  [UserWorkStatusType.OFF_SICK]: {
    emoji: emojis.off_sick,
    name: 'Off sick',
    isWorking: true,
    category: UserWorkStatusCategory.NON_WORKING,
  },
  [UserWorkStatusType.ANNUAL_LEAVE]: {
    emoji: emojis.annual_leave,
    name: 'Vacation',
    isWorking: false,
    category: UserWorkStatusCategory.NON_WORKING,
  },
  [UserWorkStatusType.OTHER_ABSENCE]: {
    emoji: emojis.other_absence,
    name: 'Away',
    isWorking: false,
    category: UserWorkStatusCategory.NON_WORKING,
  },
  [UserWorkStatusType.NO_STATUS]: {
    emoji: emojis.no_status,
    name: 'No status',
    isWorking: true, // default to true
    category: UserWorkStatusCategory.POPULAR,
  },
  [UserWorkStatusType.WORK_TRAVEL]: {
    emoji: emojis.work_travel,
    name: 'Traveling for work',
    isWorking: true,
    category: UserWorkStatusCategory.TRAVEL,
  },
  [UserWorkStatusType.CLIENT_OFFICE]: {
    emoji: emojis.client_office,
    name: 'Client offices',
    isWorking: true,
    category: UserWorkStatusCategory.TRAVEL,
  },
};

export enum DefaultExtraId {
  PARKING = 'PARKING',
  LUNCH = 'LUNCHES',
  DOG = 'DOGS',
}

export const DEFAULT_EXTRA_PROFILES = {
  [DefaultExtraId.DOG]: {
    emoji: '🐶',
    name: 'Dog',
  },
  [DefaultExtraId.LUNCH]: {
    emoji: '🍜',
    name: 'Lunch',
  },
  [DefaultExtraId.PARKING]: {
    emoji: '🅿️',
    name: 'Parking',
  },
};

// redo list above but in order=
export enum AnnouncementCategory {
  GENERAL = 0,
  DRINKS = 1,
  CELEBRATION = 2,
  HYPE = 3,
  DISRUPTION = 4,
  CLOSURE = 5,
  FOOD = 6,
  EVENT = 7,
  MOTIVATION = 8,
  BIRTHDAY = 9,
  WELCOME = 11,
}

export enum AnnouncementCategoryGroup {
  HAPPENING = 'Happening',
  SOCIAL = 'Social',
  FUN = 'Fun',
  HEADS_UP = 'Heads Up',
}

export const ANNOUNCEMENT_CATEGORY_PROFILES: Record<
  AnnouncementCategory,
  {
    emoji: string;
    nickname: string;
    group: AnnouncementCategoryGroup;
  }
> = {
  [AnnouncementCategory.GENERAL]: {
    emoji: '📣',
    nickname: 'General',
    group: AnnouncementCategoryGroup.HAPPENING,
  },
  [AnnouncementCategory.EVENT]: {
    emoji: '🎤',
    nickname: 'Event',
    group: AnnouncementCategoryGroup.HAPPENING,
  },
  [AnnouncementCategory.CELEBRATION]: {
    emoji: '🎉',
    nickname: 'Celebration',
    group: AnnouncementCategoryGroup.HAPPENING,
  },
  [AnnouncementCategory.DRINKS]: {
    emoji: '🥂',
    nickname: 'Drinks',
    group: AnnouncementCategoryGroup.SOCIAL,
  },
  [AnnouncementCategory.FOOD]: {
    emoji: '🍽️',
    nickname: 'Food',
    group: AnnouncementCategoryGroup.SOCIAL,
  },
  [AnnouncementCategory.WELCOME]: {
    emoji: '👋',
    nickname: 'Welcome',
    group: AnnouncementCategoryGroup.SOCIAL,
  },
  [AnnouncementCategory.HYPE]: {
    emoji: '🙌',
    nickname: 'Hype',
    group: AnnouncementCategoryGroup.FUN,
  },
  [AnnouncementCategory.BIRTHDAY]: {
    emoji: '🎂',
    nickname: 'Birthday',
    group: AnnouncementCategoryGroup.FUN,
  },
  [AnnouncementCategory.MOTIVATION]: {
    emoji: '💪',
    nickname: 'Motivation',
    group: AnnouncementCategoryGroup.FUN,
  },
  [AnnouncementCategory.DISRUPTION]: {
    emoji: '⚠️',
    nickname: 'Disruption',
    group: AnnouncementCategoryGroup.HEADS_UP,
  },
  [AnnouncementCategory.CLOSURE]: {
    emoji: '⛔',
    nickname: 'Closure',
    group: AnnouncementCategoryGroup.HEADS_UP,
  },
};

export const ACCOUNT_ADMIN_ROLE_CAPABILITIES_DESCRIPTION = 'manage billing, plans and all other settings in Officely';

export enum BillingPlan {
  FREE = 'free',
  SIMPLE = '2',
  PREMIUM = '3',
  SIMPLE_ANNUAL = '2.12',
  PREMIUM_ANNUAL = '3.12',
  LEGACY_PREMIUM = 'premium',
  LEGACY_PREMIUM_ANNUAL = 'premium.12',
}

export enum BillingRestrictedFeature {
  OFFICE_CHAT = 'office_chat',
  FAV_COWORKER = 'fav_coworker',
  STATUSES = 'statuses',
  ANNOUNCEMENTS = 'announcements',
  OFFICE_BROADCAST = 'office_broadcast',
  STATUS_NOTE = 'status_note',
  HRIS = 'hris',
  SMART_NOTIFICATIONS = 'smart_notifications',
}

export enum BillingDowngradeReason {
  EMPLOYEE_DIDNT_USE_IT = '1',
  TOO_EXPENSIVE = '2',
  SHUT_OFFICE = '3',
  RETURNED_TO_OFFICE = '4',
  USING_ANOTHER_TOOL = '5',
  OFFICE_MANDATES = '6',
  OTHER = '0', // Following the original order from legacy code
}

export const BILLING_DOWNGRADE_REASON_PROFILES: Record<BillingDowngradeReason, { name: string }> = {
  [BillingDowngradeReason.EMPLOYEE_DIDNT_USE_IT]: {
    name: 'Our employees didn’t use it',
  },
  [BillingDowngradeReason.TOO_EXPENSIVE]: {
    name: 'Too expensive',
  },
  [BillingDowngradeReason.SHUT_OFFICE]: {
    name: 'We have shut our office',
  },
  [BillingDowngradeReason.RETURNED_TO_OFFICE]: {
    name: 'We have returned to 5 days a week in the office',
  },
  [BillingDowngradeReason.USING_ANOTHER_TOOL]: {
    name: 'Using another tool',
  },
  [BillingDowngradeReason.OFFICE_MANDATES]: {
    name: 'moving to mandated office days',
  },
  [BillingDowngradeReason.OTHER]: {
    name: 'Other',
  },
};

const BILLING_GO_LIVE_DATE = new Date('2023-10-31T00:00:00.00Z');
const SIMPLE_PLAN_NAME = 'Simple Desk Booking';
const PREMIUM_PLAN_NAME = 'Flexible Work Enablement';
const LEGACY_PREMIUM_PLAN_NAME = 'Flexible Work Enablement (Pre-Nov 2023 pricing)';

const SIMPLE_PLAN_DESCRIPTION =
  'Limited to capacity management features, unlimited users. Manage your office capacity with a tool your team will actually use.';
const PREMIUM_PLAN_DESCRIPTION = 'All the features, unlimited users. For making flexible work, really work.';
const LEGACY_PREMIUM_PLAN_DESCRIPTION = 'All the features, unlimited users. For making flexible work, really work.';

export enum BillingPeriod {
  NONE = 'none',
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export const BILLING_PLAN_CONFIGS: Record<
  BillingPlan,
  {
    name: string;
    rank: number;
    restrictions: BillingRestrictedFeature[];
    userLimit: number;
    description: string;
    billingPeriod: BillingPeriod;
    availableFrom?: Date;
    discontinuedFrom?: Date;
  }
> = {
  [BillingPlan.FREE]: {
    name: 'Basic (free)',
    description: 'All the features, only 10 employees. Perfect to test Officely with a small team.',
    rank: 0,
    restrictions: [BillingRestrictedFeature.HRIS],
    userLimit: 10,
    billingPeriod: BillingPeriod.NONE,
  },
  [BillingPlan.SIMPLE]: {
    name: SIMPLE_PLAN_NAME,
    description: SIMPLE_PLAN_DESCRIPTION,
    rank: 1,
    restrictions: [
      BillingRestrictedFeature.OFFICE_CHAT,
      BillingRestrictedFeature.FAV_COWORKER,
      BillingRestrictedFeature.STATUSES,
      BillingRestrictedFeature.ANNOUNCEMENTS,
      BillingRestrictedFeature.OFFICE_BROADCAST,
      BillingRestrictedFeature.STATUS_NOTE,
      BillingRestrictedFeature.SMART_NOTIFICATIONS,
      BillingRestrictedFeature.HRIS,
    ],
    userLimit: Infinity,
    availableFrom: BILLING_GO_LIVE_DATE,
    billingPeriod: BillingPeriod.MONTHLY,
  },
  [BillingPlan.SIMPLE_ANNUAL]: {
    name: SIMPLE_PLAN_NAME,
    description: SIMPLE_PLAN_DESCRIPTION,
    rank: 1,
    restrictions: [
      BillingRestrictedFeature.OFFICE_CHAT,
      BillingRestrictedFeature.FAV_COWORKER,
      BillingRestrictedFeature.STATUSES,
      BillingRestrictedFeature.ANNOUNCEMENTS,
      BillingRestrictedFeature.OFFICE_BROADCAST,
      BillingRestrictedFeature.STATUS_NOTE,
      BillingRestrictedFeature.SMART_NOTIFICATIONS,
      BillingRestrictedFeature.HRIS,
    ],
    userLimit: Infinity,
    availableFrom: BILLING_GO_LIVE_DATE,
    billingPeriod: BillingPeriod.ANNUAL,
  },
  [BillingPlan.PREMIUM]: {
    name: PREMIUM_PLAN_NAME,
    description: PREMIUM_PLAN_DESCRIPTION,
    rank: 2,
    restrictions: [],
    userLimit: Infinity,
    availableFrom: BILLING_GO_LIVE_DATE,
    billingPeriod: BillingPeriod.MONTHLY,
  },
  [BillingPlan.PREMIUM_ANNUAL]: {
    name: PREMIUM_PLAN_NAME,
    description: PREMIUM_PLAN_DESCRIPTION,
    rank: 2,
    restrictions: [],
    userLimit: Infinity,
    availableFrom: BILLING_GO_LIVE_DATE,
    billingPeriod: BillingPeriod.ANNUAL,
  },
  [BillingPlan.LEGACY_PREMIUM]: {
    name: LEGACY_PREMIUM_PLAN_NAME,
    description: LEGACY_PREMIUM_PLAN_DESCRIPTION,
    rank: 2,
    restrictions: [],
    userLimit: Infinity,
    discontinuedFrom: BILLING_GO_LIVE_DATE,
    billingPeriod: BillingPeriod.MONTHLY,
  },
  [BillingPlan.LEGACY_PREMIUM_ANNUAL]: {
    name: LEGACY_PREMIUM_PLAN_NAME,
    description: LEGACY_PREMIUM_PLAN_DESCRIPTION,
    rank: 2,
    restrictions: [],
    userLimit: Infinity,
    discontinuedFrom: BILLING_GO_LIVE_DATE,
    billingPeriod: BillingPeriod.ANNUAL,
  },
};

/**
 * --------- NOTIFICATIONS -----------
 */

export enum MutePeriods {
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  ONE_MONTH = 'ONE_MONTH',
}

export const MUTE_PERIODS_PROFILES = {
  [MutePeriods.ONE_WEEK]: {
    name: '1 week',
  },
  [MutePeriods.TWO_WEEKS]: {
    name: '2 weeks',
  },
  [MutePeriods.ONE_MONTH]: {
    name: '1 month',
  },
};

// ---------- INVITES -------------

export enum InviteType {
  Parkly = 'PARKLY',
  Officely = 'OFFICELY',
  OfficeManager = 'OFFICE_MANAGER',
  AccountManager = 'ACCOUNT_MANAGER',
  OfficeDay = 'OFFICE_DAY',
  Team = 'TEAM',
  FavouriteCoworker = 'FAVOURITE_COWORKER',
}

export const INVITE_PRODUCT_RELEVANCE: Record<InviteType, Product[]> = {
  [InviteType.Parkly]: [Product.Parkly],
  [InviteType.Officely]: [Product.Officely],
  [InviteType.AccountManager]: [Product.Officely, Product.Parkly],
  [InviteType.OfficeManager]: [Product.Officely],
  [InviteType.OfficeDay]: [Product.Officely],
  [InviteType.Team]: [Product.Officely],
  [InviteType.FavouriteCoworker]: [Product.Officely],
};

// --------- USERS -------------

export enum UserRelation {
  Manager = 'MANAGER',
  DirectReport = 'DIRECT_REPORT',
  TeamMember = 'TEAM_MEMBER',
  Favourite = 'FAVOURITE',
  Invitee = 'INVITEE',
}

export const USER_RELATION_PROFILES: Record<UserRelation, { name: string }> = {
  [UserRelation.Manager]: {
    name: 'Manager',
  },
  [UserRelation.DirectReport]: {
    name: 'Direct Reports',
  },
  [UserRelation.TeamMember]: {
    name: 'Team Members',
  },
  [UserRelation.Favourite]: {
    name: 'Favorite Coworkers',
  },
  [UserRelation.Invitee]: {
    name: 'Invitees',
  },
};

// ---------- TEAMS -------------

export enum RecurringBookingSchedule {
  Weekly = '1',
  EveryTwoWeeks = '2',
  EveryThreeWeeks = '3',
  EveryFourWeeks = '4',
}

// ---------- OFFICE --------------

export const OFFICE_ADMIN_ROLE_CAPABILITIES_DESCRIPTION = 'post announcements, manage bookings and change settings';

export enum OfficeBroadcastType {
  Today = 'today',
  Tomorrow = 'tomorrow',
}

export enum OfficeBroadcastDisplayMode {
  Name = 'name',
  Avatar = 'avatar',
}

// --------- NOTIFICATIONS -------------

// TODO: remove after migration
export enum ChannelNotificationType {
  OfficeBroadcast = 'OFFICE_BROADCAST',
}

export enum UserNotificationType {
  Welcome = 'WELCOME',
  BillingPlanChanged = 'BILLING_PLAN_CHANGED',
  Invite = 'INVITE',
  InviteAccepted = 'INVITE_ACCEPTED',
  CancellationAlert = 'CANCELLATION_ALERT',
  OfficeOpportunityAlert = 'OFFICE_OPPORTUNITY_ALERT',
  OfficeCompanionAlert = 'OFFICE_COMPANION_ALERT',
  WaitlistSuccess = 'WAITLIST_SUCCESS',
  ManagerChangedYourStatus = 'MANAGER_CHANGED_YOUR_STATUS',
  CalendarConnected = 'CALENDAR_CONNECTED',
  CalendarDisconnected = 'CALENDAR_DISCONNECTED',
  OnboardingComplete = 'ONBOARDING_COMPLETE',
  IntegrationSuggestion = 'INTEGRATION_SUGGESTION',
  OfficelyHelp = 'OFFICELY_HELP',
  YouveBeenFavourited = 'YOUVE_BEEN_FAVOURITED',
  WeeklyReminder = 'WEEKLY_REMINDER',
  OfficeCheckIn = 'OFFICE_CHECK_IN',
  StatusReminder = 'STATUS_REMINDER',
}

export enum OfficeNotificationType {
  Broadcast = 'OFFICE_BROADCAST',
}

export const MANAGED_NOTIFICATION_TYPES = [
  UserNotificationType.CancellationAlert,
  UserNotificationType.OfficeOpportunityAlert,
  UserNotificationType.OfficeCompanionAlert,
  UserNotificationType.WeeklyReminder,
  UserNotificationType.StatusReminder,
] as const;

export const UNSUBSCRIBABLE_USER_RELATIONS = [UserRelation.Favourite, UserRelation.Manager, UserRelation.DirectReport];

export const MANAGED_NOTIFICATION_PROFILES: Record<
  ManagedNotificationType,
  {
    name: string;
    emoji: string;
    description: string;
    example: string;
    controls: Array<'relations' | 'whitelist' | 'blacklist' | 'disable'>;
  }
> = {
  [UserNotificationType.CancellationAlert]: {
    name: 'Cancellation Alert',
    emoji: '🚫',
    description: `Get notified when one of your coworkers cancels on you last minute, so that you don't show up to an empty office!`,
    example: `Heads up, Lucy is no longer in the office tomorrow`,
    controls: ['relations', 'whitelist', 'blacklist', 'disable'],
  },
  [UserNotificationType.OfficeOpportunityAlert]: {
    name: 'Opportunity Alert',
    emoji: '🌟',
    description: `Get notified when one of your coworkers is heading into the office so that you can decide whether to join them.`,
    example: `Hey {{name}}, Lucy is going to the office tomorrow. Join?`,
    controls: ['relations', 'whitelist', 'blacklist', 'disable'],
  },
  [UserNotificationType.OfficeCompanionAlert]: {
    name: 'Companion Alert',
    emoji: '🎉',
    description: `Get notified when one of your coworkers is planning to join you in the office.`,
    example: `Hey {{name}}, Lucy is joining you in the office tomorrow`,
    controls: ['relations', 'whitelist', 'blacklist', 'disable'],
  },
  [UserNotificationType.WeeklyReminder]: {
    name: 'Weekly Reminder',
    emoji: '📅',
    description: `Get a weekly reminder to see who's in the office for the week ahead.`,
    example: `☕  Morning {{name}}! Here's who's in the office this week...`,
    controls: ['disable'],
  },
  [UserNotificationType.StatusReminder]: {
    name: 'Status Reminders',
    emoji: '👋',
    description: `Get a reminder to set your status for the next day`,
    example: `Hi {{name}} 👋! Set your status for tomorrow`,
    controls: ['disable'],
  },
};

// -------- CALENDAR -------------

export enum CalendarProvider {
  Google = 'google',
  Outlook = 'outlook',
}

export enum CalendarStandardisedScope {
  EVENTS = 'events',
  RESOURCES = 'resources',
}

// -------- ONBOARDING ----------

export enum OnboardingFlow {
  NewUser = 'NEW_USER',
  Installer = 'INSTALLER',
  OfficeCreation = 'OFFICE_CREATION',
}

export enum OnboardingStep {
  OfficeSelection = 'OFFICE_SELECTION',
  FavCoworkerSelection = 'FAV_COWORKER_SELECTION',
  OfficeCreation = 'OFFICE_CREATION',
  OfficeNeighbourhoods = 'OFFICE_NEIGHBOURHOODS',
  OfficeExtras = 'OFFICE_EXTRAS',
  OfficeBroadcast = 'OFFICE_BROADCAST',
  OthersTestingOfficely = 'OTHERS_TESTING_OFFICELY',
  EnableStatuses = 'ENABLE_STATUSES',
}

export const ONBOARDING_FLOW_STEPS: Record<OnboardingFlow, OnboardingStep[]> = {
  NEW_USER: [OnboardingStep.OfficeSelection, OnboardingStep.FavCoworkerSelection],
  INSTALLER: [
    OnboardingStep.OfficeCreation,
    OnboardingStep.OfficeNeighbourhoods,
    OnboardingStep.OfficeExtras,
    OnboardingStep.OfficeBroadcast,
    OnboardingStep.OthersTestingOfficely,
  ],
  OFFICE_CREATION: [
    OnboardingStep.OfficeCreation,
    OnboardingStep.OfficeNeighbourhoods,
    OnboardingStep.OfficeExtras,
    OnboardingStep.OfficeBroadcast,
    OnboardingStep.OthersTestingOfficely,
  ],
};

export const ONBOARDING_STEP_PROFILES: Record<
  OnboardingStep,
  {
    heading: string;
  }
> = {
  [OnboardingStep.OfficeSelection]: {
    heading: 'Choose your office',
  },
  [OnboardingStep.FavCoworkerSelection]: {
    heading: 'Your Favourite Coworkers',
  },
  [OnboardingStep.OfficeCreation]: {
    heading: 'Create Your Office',
  },
  [OnboardingStep.OfficeNeighbourhoods]: {
    heading: 'Add Neigborhoods?',
  },
  [OnboardingStep.OfficeExtras]: {
    heading: 'Enable Office Extras?',
  },
  [OnboardingStep.OfficeBroadcast]: {
    heading: `${emojis.office_broadcast}  Enable Office Broadcast?`,
  },
  [OnboardingStep.OthersTestingOfficely]: {
    heading: 'Lastly, who else is testing Officely with you?',
  },
  [OnboardingStep.EnableStatuses]: {
    heading: 'Enable Other Statuses?',
  },
};

// -------- HRIS ----------
export enum HrisStatus {
  READY = 'ready',
  SYNCING = 'syncing',
  FAILED = 'failed',
  AUTH_FAILED = 'auth_failed',
}

export enum HrisCapability {
  EMPLOYEE_READ = 'employee.read',
  GROUP_READ = 'group.read',
  GROUP_WRITE = 'group.write',
  ABSENCE_READ = 'absence.read',
  LOCATION_READ = 'location.read',
  LEGAL_ENTITIES_READ = 'legal_entities.read',
  // not yet supported
  // ABSENCE_WRITE = 'absence.write',
}

export enum ChangeUserWorkStatusErrorCode {
  ConflictAwayDates = 'conflict_away_dates',
  AwayDatesLocked = 'away_dates_locked',
  OfficeFullyBooked = 'office_fully_booked',
  ExtrasNotAvailable = 'extras_not_available',
  NeighborhoodNotAvailable = 'neighborhood_not_available',
  StatusDisabled = 'status_disabled',
  OfficeClosed = 'office_closed',
  OfficeDeleted = 'office_deleted',
  UpgradeRequired = 'upgrade_required',
}

export enum AwayDateErrorCode {
  CONFLICT = 'conflict',
  NOT_FOUND = 'not_found',
  INVALID_DATES = 'invalid_dates',
  NOT_SUPPORTED = 'not_supported',
}

export const DEFAULT_ERROR_PROFILE = {
  title: 'Something went wrong',
  description: 'Please try again later',
};

export const ERROR_CODE_PROFILES: Record<string, { title: string; description: string }> = {
  [ChangeUserWorkStatusErrorCode.ConflictAwayDates]: {
    title: 'Conflict with Away Dates',
    description: "It looks like you're away on this date. Please remove your away dates to change your status.",
  },
  [ChangeUserWorkStatusErrorCode.AwayDatesLocked]: {
    title: 'Away Dates Locked',
    description:
      'It looks like your status is controlled by your HR system. Before you can set a new status in Officely you need to resolve any conflicts with your HR system.',
  },
  [ChangeUserWorkStatusErrorCode.OfficeFullyBooked]: {
    title: 'Office Fully Booked',
    description: 'This office is now fully booked on this day.',
  },
  [ChangeUserWorkStatusErrorCode.ExtrasNotAvailable]: {
    title: 'Extras Not Available',
    description: 'One or more extras you selected are no longer available.',
  },
  [ChangeUserWorkStatusErrorCode.NeighborhoodNotAvailable]: {
    title: 'Neighborhood Not Available',
    description: 'The selected neighborhood is no longer available.',
  },
  [ChangeUserWorkStatusErrorCode.StatusDisabled]: {
    title: 'Status Disabled',
    description: 'This status is disabled by your office manager.',
  },
  [ChangeUserWorkStatusErrorCode.OfficeClosed]: {
    title: 'Office Closed',
    description: 'This office is now closed.',
  },
  [ChangeUserWorkStatusErrorCode.OfficeDeleted]: {
    title: 'Office Deleted',
    description: 'This office has been deleted.',
  },
  [ChangeUserWorkStatusErrorCode.UpgradeRequired]: {
    title: 'Upgrade Required',
    description: 'You need to upgrade to access this feature.',
  },
  // ---------- AWAY DATES ----------
  [AwayDateErrorCode.CONFLICT]: {
    title: 'Conflict',
    description: "It looks like this clashes with another date you're away on",
  },
  [AwayDateErrorCode.NOT_FOUND]: {
    title: 'Not Found',
    description: 'Hmm, looks like this has been deleted',
  },
  [AwayDateErrorCode.INVALID_DATES]: {
    title: 'Invalid Dates',
    description: 'The dates you have selected are not valid',
  },
  [AwayDateErrorCode.NOT_SUPPORTED]: {
    title: 'Not Supported',
    description: 'Your setup does not support this type of away date',
  },
};

// ---------- USER PARKING STATUS ----------

export enum BookParkingLocationErrorCode {
  ParkingLocationFullyBooked = 'parking_location_fully_booked',
  ZoneNotAvailable = 'zone_not_available',
  ParkingLocationClosed = 'parking_location_closed',
  ParkingLocationNotFound = 'parking_location_not_found',
}

export enum UserDayCheckpoint {
  DayWillStart = 'DayWillStart', // slack status sync, refresh app home
  DayStarted = 'DayStarted', // send weekly reminder
  DayWillEnd = 'DayWillEnd', // status reminders
}

export const USER_DAY_CHECKPOINT_HOUR_MAPPING = {
  [UserDayCheckpoint.DayWillStart]: 7,
  [UserDayCheckpoint.DayStarted]: 9,
  [UserDayCheckpoint.DayWillEnd]: 16,
} as const;

export enum OfficeDayCheckPoint {
  BroadcastDue = 'BroadcastDue',
  CheckInsDue = 'CheckInsDue',
  OfficeChatStarted = 'OfficeChatStarted',
  OfficeChatEnded = 'OfficeChatEnded',
}

export const OFFICE_DAY_CHECKPOINT_HOUR_MAPPING = {
  [OfficeDayCheckPoint.CheckInsDue]: 7,
  [OfficeDayCheckPoint.OfficeChatStarted]: 9,
  [OfficeDayCheckPoint.OfficeChatEnded]: 18,
} as const;


export const NOTE_SUGGESTIONS: Record<string, string[] | undefined> = {
  [UserWorkStatusType.ANNUAL_LEAVE]: [`⛱️  On the beach`, `🏔️  Climbing a mountain`, `🌴  Lost in the jungle`],
  [UserWorkStatusType.WORK_TRAVEL]: [`🚕  On the road`, `🧳  At a conference`, `✈️  In the air`],
  [UserWorkStatusType.OFFICE]: [`🕘  Morning only`, `🕒  Afternoon only`, `🍪  Bringing cookies!`],
};

export const NOTE_PLACEHOLDERS: Record<string, string | undefined> = {
  [UserWorkStatusType.ANNUAL_LEAVE]: 'Going anywhere nice?',
  [UserWorkStatusType.WORK_TRAVEL]: 'Keep your team in the loop',
  [UserWorkStatusType.OFFICE]: 'E.g. In from 10am',
  [UserWorkStatusType.CLIENT_OFFICE]: 'Share the client name with your team?',
};