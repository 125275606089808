import { HomeView } from '@client/features/home/const';
import { yyyy_MM_dd } from '@client/lib/const';
import { yyyyMmDdSchema } from '@officely/models';
import { parse, startOfDay } from 'date-fns';
import { z } from 'zod';

export const homeSearchSchema = z
  .object({
    office: z.string().optional(),
    view: z.nativeEnum(HomeView).optional().catch(HomeView.Default),
    period: z
      .object({
        start: yyyyMmDdSchema,
        end: yyyyMmDdSchema,
      })
      .refine((data) => {
        const valid = new Date(data.start.replace(/_/g, '-')) < new Date(data.end.replace(/_/g, '-'));
        const start = parse(data.start, yyyy_MM_dd, new Date());
        const today = startOfDay(new Date());
        const notInPast = start >= today;
        return valid && notInPast;
      })
      .optional()
      .catch(undefined),
  })
  .default({});
