import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { AnnouncementForm, AnnouncementFormValues } from '@client/features/announcements/components/form';
import { useAnnouncements } from '@client/features/announcements/hook';
import { formatDate, parseDate } from '@client/lib/date';
import { CommonPropsForModal } from '@client/lib/modal';
import { AnnouncementCategory } from '@officely/models';
import { useCallback, useMemo } from 'react';

type Props = CommonPropsForModal & {
  date: string;
  officeId: string;
};

export const AnnouncementCreateModal = (props: Props) => {
  const { onDone, onClose, officeId, date, ...restForModal } = props;
  const { createAnnouncement } = useAnnouncements();

  const handleSubmit = useCallback(
    async (values: AnnouncementFormValues) => {
      await createAnnouncement({
        category: parseInt(values.category),
        text: values.text,
        channelIds: [],
        excludedDates: [],
        fromDate: formatDate(values.dates.from!),
        toDate: values.dates.to ? formatDate(values.dates.to) : formatDate(values.dates.from!),
        weekdays:
          values.specificWeekdays && !!values.weekdays?.length ? values.weekdays.map((day) => parseInt(day)) : null,
        officeIds: [officeId],
      });
      onDone?.();
      onClose?.();
    },
    [createAnnouncement, onDone, onClose, officeId]
  );

  const commonModalProps: ModalLayoutProps = {
    title: 'New Announcement',
    loading: false,
    submitFormId: AnnouncementForm.id,
    submitText: 'Create',
    closeText: props.nested ? 'Back' : 'Cancel',
    onClose,
    ...restForModal,
  };

  const data = useMemo(() => {
    return {
      dates: {
        from: parseDate(date),
      },
      category: AnnouncementCategory.GENERAL.toString(),
      text: '',
    };
  }, [date]);

  return (
    <ModalLayout {...commonModalProps}>
      <AnnouncementForm onSubmit={handleSubmit} data={data} />
    </ModalLayout>
  );
};
