/* eslint-disable sonarjs/no-small-switch */
import { CommonPropsForModal } from '@client/lib/modal';
import { Emoji } from '@client/components/ui/custom/emoji';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { Weekday, WeekdayHelper } from '@officely/models';
import { useCallback, useMemo } from 'react';
import { SetupExtraForm, SetupExtraFormValues } from '@client/features/onboarding/components/setup-extra-form';
import { ExtraData } from '@client/features/onboarding/types';
import { isoWeekdayToString } from '@client/lib/date';

type Props = CommonPropsForModal & {
  onSubmit: (data: ExtraData) => void;
  existing?: ExtraData;
};

export const SetupExtraModal = (props: Props) => {
  const { onDone, existing, onSubmit, ...restForModal } = props;

  const handleSubmit = useCallback(
    (value: SetupExtraFormValues) => {
      onSubmit({
        name: value.name,
        capacity: value.capacity,
        enabled: true,
        emoji: value.emoji,
        defaultExtraId: existing?.defaultExtraId,
        weekdays: value.daysOfWeek?.length
          ? value.daysOfWeek?.map((day) => new WeekdayHelper(day as Weekday).isoWeekday) ?? undefined
          : undefined,
      });
    },
    [onSubmit, existing]
  );

  const data = useMemo<SetupExtraFormValues>(() => {
    return {
      name: existing?.name ?? '',
      emoji: existing?.emoji ?? '',
      capacity: existing?.capacity,
      limit: existing?.capacity !== undefined || !!existing?.weekdays?.length,
      daysOfWeek: existing?.weekdays?.map((isoWeekday) => isoWeekdayToString(isoWeekday).toLowerCase()),
    };
  }, [existing]);

  const commonModalProps: ModalLayoutProps = {
    title: existing?.defaultExtraId ? 'Enable Extra' : existing?.defaultExtraId ? 'Edit Extra' : 'Create Extra',
    description: 'Use this to create bookable extras such as parking, lunches, classes etc.',
    submitFormId: SetupExtraForm.id,
    submitText: 'Submit',
    closeText: 'Cancel',
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      {existing?.defaultExtraId && (
        <div className="flex items-center mt-2">
          <Emoji lg>{data.emoji}</Emoji>
          <TypographyP className="ml-2 italic">{data.name}</TypographyP>
        </div>
      )}
      <div className="mt-4">
        <SetupExtraForm isDefault={!!existing?.defaultExtraId} onSubmit={handleSubmit} data={data} />
      </div>
    </ModalLayout>
  );
};
