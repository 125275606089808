import { Emoji } from '@client/components/ui/custom/emoji';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { Select } from '@client/components/ui/custom/select';
import { Separator } from '@client/components/ui/separator';
import { useBooking } from '@client/features/booking/hooks/use-booking';
import { getFriendlyDate } from '@client/features/home/helpers';
import { emojis } from '@client/lib/emojis';
import { CommonPropsForModal } from '@client/lib/modal';
import { trpc } from '@client/lib/trpc';
import { BookingMethod, Office, UserWorkStatus, UserWorkStatusType, UserWorkStatusTypeHelper } from '@officely/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Person } from '@client/features/app/components/person';
import { formatDate, parseDate } from '@client/lib/date';
import { OfficeBookingAvailability } from '@client/features/booking/types';
import { Input } from '@client/components/ui/input';
import { Label } from '@client/components/ui/label';
import { cn } from '@client/lib/utils';
import { SelectDateRange } from '@client/components/ui/custom/select-date-range';
import { SelectDateRangeValue } from '@client/components/ui/custom/select-date-range/types';
import { MultiSelect } from '@client/components/ui/custom/multi-select';
import { MultiSelectPeople } from '@client/components/shared/MultiSelectPeople';
import { RadioGroup, RadioGroupItem } from '@client/components/ui/radio-group';
import { Button } from '@client/components/ui/button';

type Props = CommonPropsForModal & {
  date: string;
};

type OfficesAndAvailability = Array<
  OfficeBookingAvailability & {
    office: Pick<Office, 'name' | 'emoji' | 'country'>;
  }
>;

type Values = {
  bookingAction?: 'book' | 'cancel';
  personIds: string[];
  status: UserWorkStatusType | undefined;
  dates:
    | {
        from: string;
        to?: string;
      }
    | undefined;
  office: string | undefined;
  nbh: string | undefined;
  extras: string[];
  note: string;
};

type Errors = Partial<Record<keyof Values, string>>;

function calcIsOfficeOnlyMode(types: UserWorkStatusType[]): boolean {
  return types.length === 2 && types.includes(UserWorkStatusType.OFFICE);
}

const ErrorMsg = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return <div className="text-sm italic text-red-500 mt-1">{children}</div>;
};

const Step1 = (props: { values: Values; setValues: React.Dispatch<React.SetStateAction<Values>> }) => {
  const { values, setValues } = props;

  const handlePersonChange = useCallback(
    (personIds: string[]) => {
      setValues((x) => ({ ...x, personIds }));
    },
    [setValues]
  );

  return (
    <div>
      {/* <span className="font-bold text-sm mt-1">Employees:</span> */}

      {/* <div className="flex flex-wrap gap-1 my-2">{renderedPeople}</div> */}

      <div className="mt-4 flex flex-col gap-4">
        <PersonSelect value={values.personIds} onChange={handlePersonChange} />
      </div>
    </div>
  );
};

const Step2 = (props: {
  date: string;
  bookableStatuses: UserWorkStatusType[];
  isOfficeOnlyMode: boolean;
  workStatusesForPeople: Record<string, UserWorkStatus>;
  allAvailability: OfficesAndAvailability;
  primaryPersonsStatus: UserWorkStatusType | undefined;
  values: Values;
  errors: Errors;
  setValues: React.Dispatch<React.SetStateAction<Values>>;
}) => {
  const {
    date,
    bookableStatuses,
    isOfficeOnlyMode,
    primaryPersonsStatus,
    workStatusesForPeople,
    allAvailability,
    values,
    setValues,
    errors,
  } = props;
  // console.log(date);
  const renderedPeople = useMemo(() => {
    return (
      <>
        {values.personIds.map((id) => (
          <Person key={id} id={id} variant="tag" />
        ))}
      </>
    );
  }, [values.personIds]);

  const hasOfficesWithAvailability = useMemo(() => {
    return true;
    // return (
    //   allAvailability.filter((x) => x.nbhs.filter((n) => n.spacesLeft === undefined || n.spacesLeft > 0).length > 0)
    //     .length > 0
    // );
  }, [allAvailability]);

  const availability = useMemo(() => {
    return values.office ? allAvailability.find((x) => x.officeId === values.office) : undefined;
  }, [allAvailability, values.office]);

  const hidden = useMemo(() => {
    const status = values.status as UserWorkStatusType | undefined;
    const helper = status && new UserWorkStatusTypeHelper(status);
    const isAwayDate = !!helper && helper.isAwayDate;
    const isNoStatus = !helper || helper.isNoStatus;
    const isOfficeStatus = !!helper && helper.isOffice;
    const isMultiple = values.personIds.length > 1;
    // const statusVisible = values.personIds.length > 0;
    const statusVisible = true;
    const officeVisible = isOfficeStatus && hasOfficesWithAvailability;
    const hasNbhs = availability?.nbhs?.length;
    const hasExtras = availability?.extras?.length;
    const nbhVisible = officeVisible && values.office && hasNbhs;
    const extrasVisible = officeVisible && values.office && values.nbh && hasExtras;
    const noteVisible =
      statusVisible && !isNoStatus && (officeVisible ? (hasNbhs ? !!values.nbh : !!values.office) : true);
    return {
      status: !statusVisible,
      note: !noteVisible,
      dates: !isAwayDate,
      office: !officeVisible,
      nbh: !nbhVisible,
      extras: !extrasVisible,
    };
  }, [values, hasOfficesWithAvailability, availability]);

  // const handlePersonChange = useCallback(
  //   (personIds: string[]) => {
  //     setValues((x) => ({ ...x, personIds }));
  //   },
  //   [setValues]
  // );

  const handleStatusChange = useCallback(
    (status: UserWorkStatusType | undefined) => {
      setValues((x) => ({ ...x, status }));
    },
    [setValues]
  );

  const handleOfficeChange = useCallback(
    (office: Values['office']) => {
      setValues((x) => ({ ...x, office }));
    },
    [setValues]
  );

  const handleNbhChange = useCallback(
    (nbh: Values['nbh']) => {
      setValues((x) => ({ ...x, nbh }));
    },
    [setValues]
  );

  const handleExtrasChange = useCallback(
    (extras: Values['extras']) => {
      setValues((x) => ({ ...x, extras }));
    },
    [setValues]
  );

  const handleDatesChange = useCallback(
    (dates: Values['dates']) => {
      setValues((x) => ({ ...x, dates }));
    },
    [setValues]
  );

  const handleNoteChange = useCallback(
    (note: Values['note']) => {
      setValues((x) => ({ ...x, note }));
    },
    [setValues]
  );

  const handleBookingActionChange = useCallback(
    (value: Values['bookingAction']) => {
      setValues((x) => ({ ...x, bookingAction: value }));
    },
    [setValues]
  );

  return (
    <div>
      <span className="font-bold text-sm mt-1">Employees:</span>

      <div className="flex flex-wrap gap-1 my-2">{renderedPeople}</div>

      <div className="mt-4 flex flex-col gap-4">
        {/* <PersonSelect value={values.personIds} onChange={handlePersonChange} /> */}
        {isOfficeOnlyMode ? (
          <BookingActionSelect
            hidden={hidden.status}
            workStatusesForPeople={workStatusesForPeople}
            value={values.bookingAction}
            onChange={handleBookingActionChange}
          />
        ) : (
          <StatusSelect
            hidden={hidden.status}
            isMultiPerson={values.personIds.length > 1}
            bookableStatuses={bookableStatuses}
            value={values.status}
            onChange={handleStatusChange}
          />
        )}
        <OfficeSelect
          value={values.office}
          hidden={hidden.office}
          allAvailability={allAvailability}
          onChange={handleOfficeChange}
          error={errors.office}
          numPeople={values.personIds.length}
        />
        <NbhSelect
          value={values.nbh}
          hidden={hidden.nbh}
          availability={availability?.nbhs ?? []}
          onChange={handleNbhChange}
        />
        <ExtrasSelect
          value={values.extras}
          hidden={hidden.extras}
          availability={availability?.extras ?? []}
          onChange={handleExtrasChange}
        />
        <DatesSelect value={values.dates} hidden={hidden.dates} onChange={handleDatesChange} />
        <NoteInput value={values.note} hidden={hidden.note} onChange={handleNoteChange} />
      </div>
    </div>
  );
};

const Step3 = (props: { onStartOver: () => void }) => {
  return (
    <div>
      <div className="flex items-center gap-2 mb-2">
        <Emoji lg className="mr-2">
          {emojis.white_check_mark}
        </Emoji>
        <span>Success! That's been updated.</span>
      </div>
      <Button onClick={props.onStartOver} variant={'outline'} className="mt-2">
        Change Another Status
      </Button>
    </div>
  );
};

const PersonSelect = (props: { value: string[]; onChange: (personIds: string[]) => void }) => {
  const { value, onChange } = props;

  return (
    <div>
      <div className="flex items-center gap-2 mb-2">
        <Label className="font-bold">Employees:</Label>
      </div>
      <MultiSelectPeople value={value} onChange={onChange} closeOnSelect={!value.length} />
    </div>
  );
};

const StatusSelect = (props: {
  bookableStatuses: UserWorkStatusType[];
  value: UserWorkStatusType | undefined;
  hidden: boolean;
  isMultiPerson: boolean;
  onChange: (status: UserWorkStatusType | undefined) => void;
}) => {
  const { bookableStatuses, value, hidden, onChange, isMultiPerson } = props;

  const statusOptions = useMemo(() => {
    const opts = bookableStatuses
      .map((x) => new UserWorkStatusTypeHelper(x))
      .filter((helper) => !helper.isNoStatus)
      .map((helper) => ({
        label: `${helper.profile.emoji}  ${helper.profile.name}`,
        value: helper.data,
      }));
    opts.push({
      value: UserWorkStatusType.NO_STATUS,
      label: 'None',
    });
    return opts;
  }, [bookableStatuses]);

  const selectedStatus = useMemo(() => {
    return statusOptions.find((x) => x.value === value);
  }, [statusOptions, value]);

  const handleStatusChange = useCallback(
    (status: string | undefined) => {
      onChange(status as UserWorkStatusType | undefined);
    },
    [onChange]
  );
  return (
    <div className={cn(hidden ? 'hidden' : 'visible')}>
      <div className="flex items-center gap-2 mb-2">
        <Label className="font-bold">{isMultiPerson ? 'New Status' : 'Status'}</Label>
      </div>
      <Select optionsAsBtns onChange={handleStatusChange} options={statusOptions} selected={selectedStatus} />
    </div>
  );
};

const BookingActionSelect = (props: {
  value: Values['bookingAction'];
  hidden: boolean;
  workStatusesForPeople: Record<string, UserWorkStatus>;
  onChange: (value: Values['bookingAction']) => void;
}) => {
  const { value, hidden, onChange, workStatusesForPeople } = props;

  const isMultiPerson = useMemo(() => {
    return Object.keys(workStatusesForPeople).length > 1;
  }, [workStatusesForPeople]);

  const somePeopleHaveBookings = useMemo(() => {
    return Object.values(workStatusesForPeople).some((x) => x.type === UserWorkStatusType.OFFICE);
  }, [workStatusesForPeople]);

  const handleStatusChange = useCallback(
    (value: string | undefined) => {
      onChange(value as Values['bookingAction']);
    },
    [onChange]
  );

  useEffect(() => {
    const numPeople = Object.keys(workStatusesForPeople).length;
    if (!somePeopleHaveBookings && value !== 'book' && numPeople === 1) {
      console.log('setting booking action to book');
      onChange('book');
    }
  }, [somePeopleHaveBookings, value, onChange]);

  return (
    <div className={cn(hidden ? 'hidden' : 'visible')}>
      <div className="flex items-center gap-2 mb-2">
        <Label className="font-bold">Action</Label>
      </div>
      <RadioGroup onValueChange={handleStatusChange} value={value}>
        <div className="flex items-center gap-2">
          <RadioGroupItem value="book" />
          <span>{isMultiPerson ? 'Create / update bookings' : somePeopleHaveBookings ? 'Update Booking' : 'Book'}</span>
        </div>
        {somePeopleHaveBookings && (
          <div className="flex items-center gap-2">
            <RadioGroupItem value="cancel" />
            <span>{isMultiPerson ? 'Cancel bookings' : 'Cancel booking'}</span>
          </div>
        )}
      </RadioGroup>
    </div>
  );
};

const NoteInput = (props: { value: string; hidden: boolean; onChange: (note: string) => void }) => {
  const { value, hidden, onChange } = props;
  return (
    <div className={cn(hidden ? 'hidden' : 'visible')}>
      <div className="flex items-center gap-2 mb-2">
        {/* <Emoji sm>{emojis.lower_left_fountain_pen}</Emoji> */}
        <Label className="font-bold">Note</Label>
      </div>
      <Input type="text" value={value} onChange={(e) => onChange(e.target.value)} placeholder="Add a note?" />
    </div>
  );
};

const DatesSelect = (props: {
  value: Values['dates'];
  hidden: boolean;
  onChange: (dates: Values['dates']) => void;
}) => {
  const { value, hidden, onChange } = props;

  const selected = useMemo(() => {
    return value
      ? {
          from: parseDate(value.from),
          to: value.to ? parseDate(value.to) : undefined,
        }
      : undefined;
  }, [value]);

  const handleDatesChange = useCallback(
    (value: SelectDateRangeValue) => {
      const fromFormatted = value.from ? formatDate(value.from) : undefined;
      const toFormatted = value.to ? formatDate(value.to) : undefined;
      onChange(
        fromFormatted
          ? {
              from: fromFormatted,
              to: toFormatted,
            }
          : undefined
      );
    },
    [onChange]
  );

  return (
    <div className={cn(hidden ? 'hidden' : 'visible')}>
      <div className="flex items-center gap-2 mb-2">
        <Label className="font-bold">Dates</Label>
      </div>
      <SelectDateRange selected={selected} onChange={handleDatesChange} />
    </div>
  );
};

const OfficeSelect = (props: {
  value: Values['office'];
  hidden: boolean;
  allAvailability: OfficesAndAvailability;
  numPeople: number;
  onChange: (office: Values['office']) => void;
  error: string | undefined;
}) => {
  const { value, hidden, allAvailability, onChange, error, numPeople } = props;
  // console.log(value);
  const options = useMemo(() => {
    // const withAvailability = allAvailability;
    const withAvailability = allAvailability.filter((x) => x.totalSpaceLeft > 0 || x.existingBooking);
    return withAvailability.map((x) => ({
      label: `${x.office.emoji}  ${x.office.name}`,
      value: x.officeId,
      group: withAvailability.length > 5 ? x.office.country : undefined,
    }));
  }, [allAvailability]);

  const selected = useMemo(() => {
    return options.find((x) => x.value === value);
  }, [value, options]);

  return (
    <div className={cn(hidden ? 'hidden' : 'visible')}>
      <div className="flex items-center gap-2 mb-2">
        <Label className="font-bold">Office</Label>
      </div>
      {options.length === 0 ? (
        <ErrorMsg>
          <span>There aren't any offices with sufficient neighborhood availability for these people on this date.</span>
          {numPeople > 1 && <span className="ml-1">Try a smaller group.</span>}
        </ErrorMsg>
      ) : (
        <Select optionsAsBtns={3} selected={selected} options={options} onChange={onChange} />
      )}
      <ErrorMsg>{error}</ErrorMsg>
    </div>
  );
};

const NbhSelect = (props: {
  value: Values['nbh'];
  hidden: boolean;
  availability: OfficesAndAvailability[number]['nbhs'];
  onChange: (office: Values['nbh']) => void;
}) => {
  const { value, hidden, availability, onChange } = props;

  const options = useMemo(() => {
    return availability.map((x) => ({
      label: `${emojis.neighbourhood}  ${x.name}`,
      value: x.id,
      description: Number.isFinite(x.spacesLeft) ? `${x.spacesLeft} spaces left` : undefined,
    }));
  }, [availability]);

  const selected = useMemo(() => {
    return options.find((x) => x.value === value);
  }, [value, options]);

  return (
    <div className={cn(hidden ? 'hidden' : 'visible')}>
      <div className="flex items-center gap-2 mb-2">
        <Label className="font-bold">Neighborhood</Label>
      </div>
      <Select optionsAsBtns={3} selected={selected} options={options} onChange={onChange} />
    </div>
  );
};

const ExtrasSelect = (props: {
  value: Values['extras'];
  hidden: boolean;
  availability: OfficesAndAvailability[number]['extras'];
  onChange: (office: Values['extras']) => void;
}) => {
  const { value, hidden, availability, onChange } = props;

  const options = useMemo(() => {
    return availability.map((x) => ({
      label: `${x.emoji}  ${x.name}`,
      value: x.id,
      description: Number.isFinite(x.spacesLeft) ? `${x.spacesLeft} spaces left` : undefined,
    }));
  }, [availability]);

  const selected = useMemo(() => {
    return options.filter((x) => value.includes(x.value));
  }, [value, options]);

  return (
    <div className={cn(hidden ? 'hidden' : 'visible')}>
      <div className="flex items-center gap-2 mb-2">
        <Label className="font-bold">Extras</Label>
      </div>
      <MultiSelect optionsAsBtns={3} selected={selected} options={options} onChange={onChange} />
    </div>
  );
};

export const ManageStatusesModal = (props: Props) => {
  const { date, onDone, onClose, ...restForModal } = props;

  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [errors, setErrors] = useState<Errors>({});
  const [values, setValues] = useState<Values>({
    personIds: [],
    status: undefined,
    dates: {
      from: date,
    },
    office: undefined,
    nbh: undefined,
    extras: [],
    note: '',
  });

  const { changeStatusMany } = useBooking();

  const bookingOfficeAllAvailabilityQuery = trpc.booking.office.allAvailability.useQuery(
    {
      date,
      personIds: values.personIds,
    },
    {
      enabled: !!values.personIds.length && step === 2,
    }
  );

  const workStatusGetEnabledStatusesQuery = trpc.workStatus.getEnabledStatuses.useQuery();
  const workStatusGetForPeopleQuery = trpc.workStatus.getForPeople.useQuery(
    {
      date,
      personIds: values.personIds,
    },
    {
      enabled: !!values.personIds.length && step === 2,
    }
  );

  const allAvailability = useMemo(() => {
    return bookingOfficeAllAvailabilityQuery.data?.availability ?? [];
  }, [bookingOfficeAllAvailabilityQuery.data]);

  const availability = useMemo(() => {
    return values.office ? allAvailability.find((x) => x.officeId === values.office) : undefined;
  }, [allAvailability, values.office]);

  const isOfficeOnlyMode = useMemo(() => {
    if (workStatusGetEnabledStatusesQuery.data?.bookable) {
      return calcIsOfficeOnlyMode(workStatusGetEnabledStatusesQuery.data.bookable);
    }
    return false;
  }, [workStatusGetEnabledStatusesQuery.data?.bookable]);

  const bookableStatuses = useMemo(() => {
    const bookable = workStatusGetEnabledStatusesQuery.data?.bookable ?? [];
    const hasOfficeAvailability = allAvailability.filter((x) => x.totalSpaceLeft > 0 || x.existingBooking).length > 0;
    return bookable.filter((x) => x !== UserWorkStatusType.OFFICE || hasOfficeAvailability);
  }, [workStatusGetEnabledStatusesQuery.data, allAvailability]);

  const workStatusesForPeople = useMemo(() => {
    return workStatusGetForPeopleQuery.data ?? {};
  }, [workStatusGetForPeopleQuery.data]);

  // reset values if people change
  useEffect(() => {
    setValues((x) => ({
      ...x,
      dates: {
        from: date,
      },
      note: '',
      status: undefined,
      office: undefined,
      nbh: undefined,
      extras: [],
    }));
  }, [values.personIds, setValues]);

  // set the office booking details
  useEffect(() => {
    const isOffice = values.status === UserWorkStatusType.OFFICE;
    const availabilityWithExistingBooking = allAvailability.find((x) => x.existingBooking);
    if (isOffice && availabilityWithExistingBooking) {
      setValues((x) => ({
        ...x,
        office: availabilityWithExistingBooking.officeId,
        // nbh: availabilityWithExistingBooking?.existingBooking?.nbhId,
        // extras: availabilityWithExistingBooking?.existingBooking?.extraIds ?? [],
      }));
    }
  }, [values.status, allAvailability]);

  // reset nbh and extras if office changes
  useEffect(() => {
    if (values.office) {
      setValues((x) => ({
        ...x,
        nbh:
          availability?.existingBooking?.nbhId ?? availability?.suggestedBooking?.nbhId ?? availability?.nbhs?.[0]?.id,
        extras: availability?.existingBooking?.extraIds ?? availability?.suggestedBooking?.extraIds ?? [],
      }));
    }
  }, [values.office, availability]);

  const primaryPersonsStatus = useMemo(() => {
    if (Object.keys(workStatusesForPeople).length === 1) {
      return Object.values(workStatusesForPeople)[0];
    }
  }, [workStatusesForPeople]);

  // patch status based on booking action
  useEffect(() => {
    if (values.bookingAction === 'book') {
      setValues((x) => ({ ...x, status: UserWorkStatusType.OFFICE }));
    } else {
      setValues((x) => ({ ...x, status: UserWorkStatusType.NO_STATUS }));
    }
  }, [values.bookingAction, setValues]);

  // set the status and note based on the primary persons status
  useEffect(() => {
    if (primaryPersonsStatus) {
      setValues((x) => ({
        ...x,
        bookingAction: primaryPersonsStatus.type === UserWorkStatusType.OFFICE ? 'book' : undefined,
        status: primaryPersonsStatus.type,
        note: primaryPersonsStatus.note ?? '',
        dates: primaryPersonsStatus.fromDate
          ? {
              from: primaryPersonsStatus.fromDate,
              to: primaryPersonsStatus.toDate,
            }
          : x.dates,
      }));
    }
  }, [primaryPersonsStatus, setValues]);

  // modify the note based on the status
  useEffect(() => {
    if (values.status !== primaryPersonsStatus?.type && primaryPersonsStatus?.note) {
      setValues((x) => ({ ...x, note: '' }));
    } else if (values.status === primaryPersonsStatus?.type && primaryPersonsStatus?.note) {
      setValues((x) => ({ ...x, note: primaryPersonsStatus.note ?? '' }));
    }
  }, [values.status, primaryPersonsStatus, setValues]);

  const handleModalClose = useCallback(() => {
    if (step === 2) {
      setStep(1);
    } else {
      onClose?.();
    }
  }, [onClose, step, setStep]);

  const handleSubmit = useCallback(async () => {
    if (step === 1) {
      setStep(2);
      return;
    }

    // validate values
    const errorsUpdate: Partial<Record<keyof Values, string>> = {};
    if (values.status === UserWorkStatusType.OFFICE) {
      if (!values.office) {
        errorsUpdate.office = 'Please select an office';
      }
      if (!values.nbh) {
        errorsUpdate.nbh = 'Please select a neighborhood';
      }
    }
    if (Object.keys(errorsUpdate).length) {
      setErrors(errorsUpdate);
      return;
    }
    setErrors({});
    const success = await changeStatusMany({
      date,
      personIds: values.personIds,
      typeOrOfficeId: values.status === UserWorkStatusType.OFFICE ? values.office! : values.status!,
      bookingMethod: BookingMethod.MANAGER,
      note: values.note,
      nbhId: values.nbh,
      extraIds: values.extras,
      dates: values.dates,
    });
    if (success) {
      setStep(3);
      onDone?.();
    }
  }, [values, onDone, changeStatusMany, onDone]);

  const handleStartOver = useCallback(() => {
    setValues((x) => ({
      ...x,
      personIds: [],
    }));
    setStep(1);
  }, [setValues, setStep]);

  const loading = useMemo(() => {
    return (
      workStatusGetForPeopleQuery.isLoading ||
      workStatusGetEnabledStatusesQuery.isLoading ||
      bookingOfficeAllAvailabilityQuery.isLoading
    );
  }, [
    workStatusGetForPeopleQuery.isLoading,
    workStatusGetEnabledStatusesQuery.isLoading,
    bookingOfficeAllAvailabilityQuery.isLoading,
  ]);

  const commonModalProps: ModalLayoutProps = {
    title: isOfficeOnlyMode ? 'Manage Bookings' : 'Manage Statuses',
    loading,
    submitText: step === 1 ? 'Next' : 'Submit',
    closeText: step === 3 ? 'Done' : props.nested || step > 1 ? 'Back' : 'Cancel',
    onSubmit: step < 3 ? handleSubmit : undefined,
    onClose: handleModalClose,
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <div className="flex items-center gap-2">
        <Emoji sm>{emojis.calendar}</Emoji>
        <span className="text-sm font-medium italic text-muted-foreground">{getFriendlyDate(date)}</span>
      </div>
      <Separator className="my-2" />
      {step === 1 ? (
        <Step1 values={values} setValues={setValues} />
      ) : step === 2 ? (
        <Step2
          date={date}
          bookableStatuses={bookableStatuses}
          isOfficeOnlyMode={isOfficeOnlyMode}
          workStatusesForPeople={workStatusesForPeople}
          allAvailability={allAvailability}
          primaryPersonsStatus={primaryPersonsStatus?.type}
          values={values}
          errors={errors}
          setValues={setValues}
        />
      ) : (
        <Step3 onStartOver={handleStartOver} />
      )}
    </ModalLayout>
  );
};
