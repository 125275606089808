import { Button } from '@client/components/ui/button';
import { DashedBtn } from '@client/components/ui/custom/dashed-btn';
import { Emoji } from '@client/components/ui/custom/emoji';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { Select } from '@client/components/ui/custom/select';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { AnnouncementCreateModal } from '@client/features/announcements/components/create-modal';
import { AnnouncementEditModal } from '@client/features/announcements/components/edit-modal';
import { useAnnouncements } from '@client/features/announcements/hook';
import { CommonPropsForModal } from '@client/lib/modal';
import { trpc } from '@client/lib/trpc';
import {
  ANNOUNCEMENT_CATEGORY_PROFILES,
  AnnouncementCategory,
  OfficeAnnouncement,
  OfficeAnnouncementHelper,
} from '@officely/models';
import { PlusCircledIcon } from '@radix-ui/react-icons';
import _ from 'lodash';
import { EllipsisIcon, PencilIcon, Trash2Icon } from 'lucide-react';

import { useCallback, useEffect, useMemo, useState } from 'react';

type Props = CommonPropsForModal & {
  date: string;
  officeId: string;
  id?: string;
};

const Announcement = (
  props: OfficeAnnouncement & {
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
  }
) => {
  const { onEdit, onDelete, ...announcement } = props;
  const { id, text, category } = announcement;
  const [expanded, setExpanded] = useState(false);
  const helper = new OfficeAnnouncementHelper(announcement);
  const { emoji } = ANNOUNCEMENT_CATEGORY_PROFILES[category as AnnouncementCategory];
  const limit = 150;
  const dateText = useMemo(() => helper.getRelativeDatesText(), [helper]);
  const isTruncated = useMemo(() => text.length > limit, [text]);
  const renderedText = useMemo(() => {
    if (expanded) {
      return text;
    }
    return _.truncate(text, { length: limit });
  }, [text, expanded]);

  const handleEdit = useCallback(() => {
    onEdit?.(id);
  }, [id, onEdit]);

  const handleDelete = useCallback(() => {
    onDelete?.(id);
  }, [id, onDelete]);

  const handleSelect = useCallback(
    (value: string | undefined) => {
      if (value === 'Edit') {
        handleEdit();
      } else if (value === 'Delete') {
        handleDelete();
      }
    },
    [handleEdit, handleDelete]
  );

  const haveActions = useMemo(() => {
    return !!onEdit || !!onDelete;
  }, [onEdit, onDelete]);

  return (
    <div className="flex items-start justify-between">
      <div>
        <div className="flex items-center">
          <Emoji lg>{emoji}</Emoji>
          <TypographyP className="ml-2 font-bold italic">{dateText}</TypographyP>
        </div>

        <span>{renderedText}</span>
        {isTruncated && !expanded && (
          <Button variant={'ghost'} size={'sm'} className="ml-1" onClick={() => setExpanded(true)}>
            Show more
          </Button>
        )}
      </div>
      {/* <Button variant={'outline'} size={'icon'} className="ml-1 ml-auto" onClick={handleEdit}>
        <EllipsisIcon className="w-4 h-4" />
      </Button> */}
      {haveActions && (
        <div className="ml-1">
          <Select
            asBtn
            asBtnProps={{ variant: 'outline', size: 'icon' }}
            placeholder=""
            placeholderIcon={<EllipsisIcon className="w-4 h-4" />}
            options={[
              { value: 'Edit', icon: <PencilIcon className="w-4 h-4" /> },
              { value: 'Delete', icon: <Trash2Icon className="w-4 h-4" /> },
            ]}
            onChange={handleSelect}
          />
        </div>
      )}
    </div>
  );
};

export const AnnouncementListModal = (props: Props) => {
  const { date, officeId, onDone, onClose, ...restForModal } = props;

  const [hasDeleted, setHasDeleted] = useState(false);
  const [editId, setEditId] = useState<string | undefined>(props.id);
  const [createOpen, setCreateOpen] = useState(false);

  const officeListAnnouncementsQuery = trpc.office.listAnnouncements.useQuery({
    date,
    officeId,
  });

  useEffect(() => {
    const len = officeListAnnouncementsQuery.data?.length;
    if (len === 0 && !hasDeleted) {
      setCreateOpen(true);
    }
  }, [officeListAnnouncementsQuery.data, hasDeleted]);

  const announcements = officeListAnnouncementsQuery.data;
  const { deleteAnnouncement } = useAnnouncements();

  const editingAnnouncement = useMemo(() => {
    return announcements?.find((a) => a.id === editId);
  }, [announcements, editId]);

  const commonModalProps: ModalLayoutProps = {
    title: 'Announcements',
    loading: officeListAnnouncementsQuery.isLoading,
    // submitFormId: OfficeBookingForm.id,
    // submitText: existingBooking ? 'Update' : 'Book',
    closeText: props.nested ? 'Back' : 'Close',
    ...restForModal,
  };

  const onDoneAndClose = useCallback(() => {
    onDone?.();
    onClose?.();
  }, [onDone, onClose]);

  const handleDelete = useCallback(
    async (id: string) => {
      setHasDeleted(true);
      await deleteAnnouncement(id);
      onDone?.();
    },
    [deleteAnnouncement, onDone]
  );

  const handleCreate = useCallback(() => {
    setCreateOpen(true);
  }, []);

  const handleEdit = useCallback((id: string) => {
    setEditId(id);
  }, []);

  const closeEdit = useCallback(() => {
    setEditId(undefined);
    if (props.id) {
      onDoneAndClose();
    }
  }, [props.id, onDoneAndClose]);

  const closeCreate = useCallback(() => {
    setCreateOpen(false);
    if (announcements?.length === 0) {
      onDoneAndClose();
    }
  }, [announcements?.length, onDoneAndClose]);

  if (createOpen) {
    return (
      <AnnouncementCreateModal nested date={date} officeId={officeId} onDone={closeCreate} onClose={closeCreate} />
    );
  }

  if (editingAnnouncement) {
    return <AnnouncementEditModal data={editingAnnouncement} nested onDone={closeEdit} onClose={closeEdit} />;
  }

  return (
    <ModalLayout {...commonModalProps} onClose={onDoneAndClose}>
      <div className="flex flex-col gap-4">
        {announcements?.map((a) => <Announcement key={a.id} {...a} onDelete={handleDelete} onEdit={handleEdit} />)}
      </div>
      <DashedBtn onClick={handleCreate}>
        <PlusCircledIcon className="w-6 h-6 mr-4 text-muted-foreground" />
        <TypographyP className="text-center text-muted-foreground text-sm italic">Add Announcement</TypographyP>
      </DashedBtn>
    </ModalLayout>
  );
};
