/* eslint-disable sonarjs/no-small-switch */
import { createContext, useContext, useState, PropsWithChildren, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { trpc } from '@client/lib/trpc';
import { User, Office as _Office } from '@officely/models';

// ------------- DIRECTORY TYPES -------------

export type Person = {
  id: string;
  name: string;
  email: string | undefined;
  avatar?: string;
  user?: Pick<User, 'id'>;
};

export type Channel = {
  id: string;
  name: string;
  teamId: string;
  teamName: string;
};

export type OfficeProfile = Pick<
  _Office,
  'id' | 'name' | 'emoji' | 'address' | 'managerIds' | 'country' | 'managerIds'
>;

type ContextActions = {
  eagerLoadPeople: (ids: string[]) => Promise<void>;
  eagerLoadOfficelyUsers: (ids: string[]) => Promise<void>;
  invalidateOffices: () => Promise<void>;
};

type ContextType = {
  people: {
    currentPerson: Person;
    loading: boolean;
    data: Record<string, Person>;
    byUserId: Record<string, string>;
  };
  channels: {
    loading: boolean;
    data: Record<string, Channel>;
  };
  offices: {
    loading: boolean;
    data: Record<string, OfficeProfile>;
  };
  actions: ContextActions;
};

const defaultContext: ContextType = {
  people: {
    currentPerson: {} as Person,
    loading: true,
    data: {},
    byUserId: {},
  },
  channels: {
    loading: true,
    data: {},
  },
  offices: {
    loading: true,
    data: {},
  },
  actions: {
    eagerLoadPeople: async () => {},
    eagerLoadOfficelyUsers: async () => {},
    invalidateOffices: async () => {},
  },
};

// ----------------- CONTEXT -----------------

const DirectoryContext = createContext<ContextType>(defaultContext);

export const DirectoryContextWrapper = ({
  children,
  currentPerson,
}: PropsWithChildren<{
  currentPerson: Person;
}>) => {
  const trpcUtils = trpc.useUtils();
  const peopleListAllQuery = trpc.directory.people.listAll.useQuery(undefined, {
    staleTime: 1000 * 60 * 5,
  });
  const channelListAllQuery = trpc.directory.channel.listAll.useQuery(undefined, {
    staleTime: 1000 * 60 * 5,
  });
  const officeListAllQuery = trpc.directory.office.listAll.useQuery(undefined, {
    staleTime: 1000 * 60 * 5,
  });

  // ------ STATE -------

  const [fetchingPeopleIds, setFetchingPeopleIds] = useState<string[]>([]);
  const [fetchingUserIds, setFetchingUserIds] = useState<string[]>([]);
  const [people, setPeople] = useState<ContextType['people']['data']>({
    // ensure that the current user is always in the directory
    [currentPerson.id]: currentPerson,
  });

  const [channels, setChannels] = useState<ContextType['channels']['data']>({});
  const [offices, setOffices] = useState<ContextType['offices']['data']>({});

  const byUserId = useMemo(() => {
    return Object.fromEntries(
      Object.values(people)
        .filter((person) => !!person.user?.id)
        .map((person) => [person.user!.id, person.id])
    );
  }, [people]);

  // ------- FUNCTIONS FOR UPDATING THE CONTEXT -------

  /**
   * Update the MS Users in the context
   * @param data
   * @returns
   * @example
   * ```tsx
   * const { updateMSUsers } = useDirectoryContext();
   * updateMSUsers({ '123': { id: '123', name: 'John Doe' } });
   * ```
   * @example
   * ```tsx
   * const { updateMSUsers } = useDirectoryContext();
   * updateMSUsers({ '123': null });
   * ```
   */
  const updatePeople = useCallback(
    (data: Record<string, Person | null>) => {
      const newData = Object.fromEntries(
        Object.entries({ ...people, ...data })
          .filter(([_key, value]) => value !== null)
          .map(([key, value]) => {
            if (key === currentPerson.id) {
              return [key, { ...value, isSelf: true }];
            }
            return [key, value];
          })
      ) as Record<string, Person>;
      setPeople(newData);
    },
    [people, setPeople, currentPerson.id]
  );

  const eagerLoadPeople = useCallback(
    async (ids: string[]) => {
      try {
        const idsNotAlreadyFetched = ids.filter((id) => !people[id] && !fetchingPeopleIds.includes(id));
        if (idsNotAlreadyFetched.length === 0) return;
        setFetchingPeopleIds(_.uniq([...fetchingPeopleIds, ...idsNotAlreadyFetched]));
        const results = await trpcUtils.directory.people.getByIds.fetch({
          ids: idsNotAlreadyFetched,
        });
        updatePeople(Object.fromEntries(results.map((person) => [person.id, person])));
      } catch (error) {
        console.error('Error eager loading people', error);
      }
    },
    [people, fetchingPeopleIds, updatePeople, setFetchingPeopleIds]
  );

  const eagerLoadOfficelyUsers = useCallback(
    async (userIds: string[]) => {
      const idsNotAlreadyFetched = userIds.filter((id) => !byUserId[id] && !fetchingUserIds.includes(id));
      if (idsNotAlreadyFetched.length === 0) return;
      setFetchingUserIds(_.uniq([...fetchingUserIds, ...idsNotAlreadyFetched]));
      const results = await trpcUtils.directory.people.getByUserIds.fetch({
        userIds: idsNotAlreadyFetched,
      });
      updatePeople(Object.fromEntries(results.map((person) => [person.id, person])));
    },
    [byUserId, fetchingUserIds, updatePeople, setFetchingUserIds, trpcUtils.directory.people.getByUserIds.fetch]
  );

  const invalidateOffices = useCallback(async () => {
    await officeListAllQuery.refetch();
  }, [officeListAllQuery]);

  // -------- EFFECTS -------

  useEffect(() => {
    if (fetchingPeopleIds.some((id) => people[id])) {
      setFetchingPeopleIds(_.uniq(fetchingPeopleIds.filter((id) => !people[id])));
    }
  }, [people, fetchingPeopleIds]);

  useEffect(() => {
    if (fetchingUserIds.some((id) => byUserId[id])) {
      setFetchingUserIds(_.uniq(fetchingUserIds.filter((id) => !byUserId[id])));
    }
  }, [byUserId, fetchingUserIds]);

  // handle people load
  useEffect(() => {
    if (peopleListAllQuery.data) {
      updatePeople(Object.fromEntries(peopleListAllQuery.data.map((person) => [person.id, person])));
    }
  }, [peopleListAllQuery.data]);

  // handle office load
  useEffect(() => {
    if (officeListAllQuery.data) {
      setOffices(Object.fromEntries(officeListAllQuery.data.map((office) => [office.id, office])));
    }
  }, [officeListAllQuery.data]);

  // handle channels load
  useEffect(() => {
    if (channelListAllQuery.data) {
      setChannels(Object.fromEntries(channelListAllQuery.data.map((channel) => [channel.id, channel])));
    }
  }, [channelListAllQuery.data]);

  const directoryContext: ContextType = {
    people: {
      currentPerson,
      loading: peopleListAllQuery.isLoading,
      data: people,
      byUserId,
    },
    channels: {
      loading: channelListAllQuery.isLoading,
      data: channels,
    },
    offices: {
      loading: officeListAllQuery.isLoading,
      data: offices,
    },
    actions: {
      eagerLoadPeople,
      eagerLoadOfficelyUsers,
      invalidateOffices,
    },
  };

  return <DirectoryContext.Provider value={directoryContext}>{children}</DirectoryContext.Provider>;
};

export function useDirectoryContext(): ContextType {
  return useContext(DirectoryContext) as ContextType;
}

// --------------- HOOKS ---------------

type DirectorySearchType = 'people';
type DirectorySearchResults<T extends DirectorySearchType> = T extends 'msUser' ? Person[] : never;

export const useDirectorySearch = <T extends DirectorySearchType>(args: {
  type: T;
  searchTerm?: string;
  limit?: number;
}): {
  results: DirectorySearchResults<T>;
  loading: boolean;
} => {
  const { type, searchTerm, limit = 5 } = args;
  const { people } = useDirectoryContext();

  switch (type) {
    case 'people':
      return {
        results: Object.values(people.data).filter((person) => {
          if (!searchTerm) return true;
          return person.name.toLowerCase().includes(searchTerm.toLowerCase());
        }) as DirectorySearchResults<T>,
        loading: Object.values(people.data).length >= limit || people.loading,
      };
    default:
      throw new Error('Invalid directory type');
  }
};

export const useEagerLoadOfficelyUsers = (userIds: string[]) => {
  const { actions, people } = useDirectoryContext();

  useEffect(() => {
    actions.eagerLoadOfficelyUsers(userIds);
  }, [userIds, actions]);

  const peopleIds = useMemo(() => userIds.map((id) => people.byUserId[id]).filter(Boolean), [people.byUserId, userIds]);
  const loading = useMemo(() => peopleIds.length < userIds.length, [peopleIds, userIds]);
  return {
    peopleIds,
    loading,
  };
};