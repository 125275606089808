import z from 'zod';
import { createFileRoute, redirect, useRouter } from '@tanstack/react-router';
import { onboardingFlowSchema, OnboardingFlow, OnboardingStep } from '@officely/models';
import { useNavigate } from '@tanstack/react-router';
import { useOnboarding } from '../../features/onboarding/hook';
import { OnboardingLayout } from '@client/features/onboarding/components/layout';
import { OfficeSelection } from '@client/features/onboarding/components/office-selection';
import { StepComponentProps } from '@client/features/onboarding/types';
import { CreateOffice } from '@client/features/onboarding/components/create-office';
import { AddNeighbourhoods } from '@client/features/onboarding/components/add-nbhs';
import { AddExtras } from '@client/features/onboarding/components/add-extras';
import { OfficeBroadcast } from '@client/features/onboarding/components/office-broadcast';
import { OthersTesting } from '@client/features/onboarding/components/others-testing';
import { FavouriteCoworkerSelection } from '@client/features/onboarding/components/fav-coworkers';
import { EnableStatuses } from '@client/features/onboarding/components/enable-statuses';

function SetupComponent() {
  const router = useRouter();
  const { userName, msUserId, officeCount } = Route.useLoaderData();
  const searchParams = Route.useSearch();

  const navigate = useNavigate({ from: Route.fullPath });

  const onboarding = useOnboarding({
    officeCount,
    flow: searchParams.flow,
    onExit: () => {
      void navigate({
        to: searchParams.return?.path ?? '/home',
        search: searchParams.return?.search ?? {},
        replace: true,
      });
    },
    onSuccess: async () => {
      if (searchParams.flow) {
        // handle office creation flow
        await navigate({
          to: searchParams.return?.path ?? '/home',
          search: searchParams.return?.search ?? {},
          replace: true,
        });
        await router.invalidate();
      } else {
        await router.invalidate();
      }
    },
  });

  const commonStepProps: StepComponentProps = {
    state: onboarding.state,
    updateState: onboarding.update,
    onBack: onboarding.back,
    onNext: onboarding.next,
  };

  return (
    <OnboardingLayout
      steps={onboarding.state.steps}
      currentStep={onboarding.step}
      name={userName}
      submitting={onboarding.submitting}
      exit={onboarding.exit}>
      {onboarding.step === OnboardingStep.OfficeSelection ? (
        <OfficeSelection {...commonStepProps} />
      ) : onboarding.step === OnboardingStep.OfficeCreation ? (
        <CreateOffice {...commonStepProps} msUserId={msUserId} />
      ) : onboarding.step === OnboardingStep.OfficeNeighbourhoods ? (
        <AddNeighbourhoods {...commonStepProps} />
      ) : onboarding.step === OnboardingStep.OfficeExtras ? (
        <AddExtras {...commonStepProps} />
      ) : onboarding.step === OnboardingStep.OfficeBroadcast ? (
        <OfficeBroadcast {...commonStepProps} />
      ) : onboarding.step === OnboardingStep.OthersTestingOfficely ? (
        <OthersTesting {...commonStepProps} />
      ) : onboarding.step === OnboardingStep.FavCoworkerSelection ? (
        <FavouriteCoworkerSelection {...commonStepProps} />
      ) : onboarding.step === OnboardingStep.EnableStatuses ? (
        <EnableStatuses {...commonStepProps} />
      ) : null}
    </OnboardingLayout>
  );
}

export const Route = createFileRoute('/_authenticated/setup')({
  component: SetupComponent,
  staleTime: 1000 * 60 * 5, // 5 minute
  validateSearch: z.object({
    flow: onboardingFlowSchema.optional().catch(undefined),
    officeId: z.string().optional().catch(undefined),
    return: z
      .object({
        path: z.string(),
        search: z.record(z.any()).optional().catch(undefined),
      })
      .optional()
      .catch(undefined),
  }),
  beforeLoad: async ({ context, search }) => {
    // onboarding check
    const flow = search.flow;
    const user = await context.trpcUtils.user.current.fetch();
    const userIsOnboarded = !!user.onboardedAt && !!user.officeIds.length;
    if (userIsOnboarded && flow !== OnboardingFlow.OfficeCreation) {
      redirect({
        to: '/home',
        throw: true,
      });
    }
    return {
      ...context,
      user,
    };
  },
  loader: async ({ context }) => {
    const { user } = context;
    const officeCount = await context.trpcUtils.office.getOfficeCount.fetch();
    return {
      officeCount,
      userName: user.profile.name,
      msUserId: user.msUserId,
    };
  },
});
