import { CommonPropsForForm, FormValues, InferFormState, LooseDefinition } from '@client/lib/form/types';
import { useForm } from '@client/lib/form/useForm';
import { useCallback, useEffect } from 'react';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { cn } from '@client/lib/utils';

export type SimpleFormProps<T extends LooseDefinition> = CommonPropsForForm<FormValues<T>> & {
  definition: T;
  onStateChange?: (state: InferFormState<T>) => void;
  onValuesChange?: (values: FormValues<T>) => void;
  gap?: 'sm';
};

export const SimpleForm = <T extends LooseDefinition>(props: SimpleFormProps<T>) => {
  const { definition, data, onSubmit, onDirty, onStateChange, onValuesChange, gap } = props;

  const { form, setValues, conditions } = useForm(definition, {
    onValuesChange,
  });

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues(data);
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      onSubmit?.(values);
      form.reset(values);
    }),
    [form, onSubmit]
  );

  useEffect(() => {
    onDirty?.(form.formState.isDirty);
  }, [form.formState.isDirty]);

  useEffect(() => {
    onStateChange?.(form.formState);
  }, [form.formState]);

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit}
        className={cn('flex flex-col', {
          'gap-y-3': gap === 'sm',
          'gap-y-6': !gap,
        })}
        id={definition.id}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
    </Form>
  );
};
