import { Select, SelectProps } from '@client/components/ui/custom/select';
import { DayAction, DayActionType, OnDayAction } from '@client/features/home/types';
import { emojis } from '@client/lib/emojis';
import { UserWorkStatusType } from '@officely/models';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { ReactNode } from '@tanstack/react-router';
import { useCallback, useMemo } from 'react';

type Props = {
  date: string;
  officeId: string;
  icon?: ReactNode;
  isAdmin?: boolean;
  isBooked: boolean;
  announcementCount: number;
  exclude?: DayActionType[];
  bookableStatuses: UserWorkStatusType[];
  onDayAction: OnDayAction;
  selectProps?: Pick<SelectProps, 'popoverOpts'>;
};

export const DayActionsSelect = (props: Props) => {
  const {
    date,
    officeId,
    announcementCount,
    exclude = [],
    selectProps = {},
    isAdmin = false,
    isBooked = false,
    onDayAction,
    bookableStatuses,
  } = props;

  const Icon = props.icon ?? DotsHorizontalIcon;

  const actions = useMemo<Array<DayAction & { hidden?: boolean; label: string }>>(() => {
    return [
      {
        type: DayActionType.SeeDetails,
        date,
        label: `${emojis.magnifying_glass}   View Full List`,
      },
      {
        type: DayActionType.ModifyBooking,
        date,
        officeId,
        hidden: !isBooked,
        label: `${emojis.office}   Modify Booking`,
      },
      {
        type: DayActionType.CreateAnnouncement,
        date,
        officeId,
        hidden: !isAdmin || announcementCount > 0,
        label: `${emojis.mega}   Post Announcement`,
      },
      {
        type: DayActionType.ManageAnnouncements,
        date,
        officeId,
        hidden: !isAdmin || announcementCount === 0,
        label: `${emojis.mega}   Manage Announcements`,
      },
      {
        type: DayActionType.ManageStatuses,
        date,
        officeId,
        bookableStatuses,
        hidden: !isAdmin,
        label: `${emojis.hammer_and_wrench}   Manage All Bookings`,
      },
    ];
  }, [date, officeId, isAdmin, isBooked, announcementCount, bookableStatuses]);

  const handleChange = useCallback(
    (value: string | undefined) => {
      const action = actions.find((action) => action.type === value);
      if (action) {
        onDayAction(action);
      }
    },
    [actions, onDayAction]
  );

  const options = useMemo(() => {
    return actions
      .filter((action) => !action.hidden && !exclude.includes(action.type))
      .map((action) => ({
        value: action.type,
        label: action.label,
      }));
  }, [actions]);

  const { popoverOpts } = selectProps;

  return options.length > 0 ? (
    <Select
      options={options}
      onChange={handleChange}
      asBtn
      asBtnProps={{ variant: 'outline', size: 'icon' }}
      placeholder=""
      placeholderIcon={<Icon />}
      popoverOpts={popoverOpts}
      disableSearch
    />
  ) : null;
};
