import { DetailView, OfficeDayData } from '@client/features/home/types';
import { createFileRoute, Link } from '@tanstack/react-router';
import { useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@client/components/ui/table';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@client/components/ui/breadcrumb';
import { homeSearchSchema } from '@client/features/home/schemas';
import { format } from 'date-fns';
import { parseDate } from '@client/lib/date';
import { DefaultLayout } from '@client/components/layout/DefaultLayout';
import { TypographyH1 } from '@client/components/ui/custom/typography-h1';
import { TypographyH3 } from '@client/components/ui/custom/typography-h3';
import { SettingsLayout } from '@client/components/layout/SettingsLayout';
import { Emoji } from '@client/components/ui/custom/emoji';
import { UserListItem } from '../../features/home/types';
import { Badge } from '@client/components/ui/badge';
import { TypographyH4 } from '@client/components/ui/custom/typography-h4';
import { Separator } from '@client/components/ui/separator';

type TableStatuses = {
  [statusOrOfficeId: string]: {
    name: string;
    emoji: string;
  };
};

type TableData = Array<{
  userId: string;
  name: string;
  note?: string;
  nbh?: string;
  extras?: string[];
  awayDatesUntil?: string;
}>;

const BreadcrumbNav = () => {
  const { date } = Route.useParams();
  const search = Route.useSearch();

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbLink asChild>
          <Link to="/home" search={search}>
            Home
          </Link>
        </BreadcrumbLink>
        <BreadcrumbSeparator />
        <BreadcrumbItem>Manage Bookings</BreadcrumbItem>
        {/* <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{format(parseDate(date), 'dd MMM yyyy')}</BreadcrumbPage>
        </BreadcrumbItem> */}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const NbhTag = (props: { nbhId: string; name: string }) => {
  const { nbhId, name } = props;
  return (
    <Badge variant={'outline'} className="rounded-full">
      {name}
    </Badge>
  );
};

const StatusTitle = (props: { name: string; emoji: string }) => {
  const { name, emoji } = props;
  return (
    <div>
      <div className="flex items-center gap-2">
        <Emoji>{emoji}</Emoji>
        <span className="font-bold">{name}</span>
      </div>
      {/* <Separator /> */}
    </div>
  );
};

const StatusesTableOffice = (props: { officeId: string; name: string; emoji: string; data: OfficeDayData }) => {
  const { name, emoji, data } = props;
  const { bookings, bookedNbhs } = data;

  const tableData = useMemo<TableData>(() => {
    const result: TableData = [];
    bookings.forEach((booking) => {
      result.push({
        userId: booking.userId,
        name: booking.name,
        note: booking.note,
        nbh: booking.nbhId ? bookedNbhs?.find((nbh) => nbh.id === booking.nbhId)?.name : undefined,
        extras: booking.extraEmojis,
      });
    });
    result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
  }, [bookings, bookedNbhs]);

  return (
    <div>
      <StatusTitle name={name} emoji={emoji} />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Note</TableHead>
            <TableHead>Neighborhood</TableHead>
            <TableHead>Extras</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={row.userId}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.note}</TableCell>
              <TableCell>{row.nbh && <NbhTag nbhId={row.nbh} name={row.nbh} />}</TableCell>
              <TableCell className="flex gap-2">
                {row.extras?.map((extra) => <Emoji key={extra}>{extra}</Emoji>)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const StatusesTableOther = (props: { status: string; name: string; emoji: string; data: UserListItem[] }) => {
  const { name, emoji, data } = props;

  const tableData = useMemo<TableData>(() => {
    const result: TableData = [];
    data.forEach((item) => {
      result.push({
        userId: item.userId,
        name: item.name,
        note: item.note,
      });
    });
    result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
  }, [data]);

  return (
    <div>
      <StatusTitle name={name} emoji={emoji} />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Note</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={row.userId}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.note}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const HomeManageAllBookings = () => {
  const { date } = Route.useParams();
  const { statuses } = Route.useLoaderData();

  const renderTables = useMemo(() => {
    return Object.entries(statuses).map(([key, value]) => {
      if (value.isOffice) {
        return <StatusesTableOffice key={key} officeId={key} name={value.name} emoji={value.emoji} data={value.data} />;
      } else {
        return <StatusesTableOther key={key} status={key} name={value.name} emoji={value.emoji} data={value.data} />;
      }
    });
  }, [statuses]);

  return (
    <SettingsLayout>
      <BreadcrumbNav />
      <div className="mt-4">
        <TypographyH3>{format(parseDate(date), 'EEEE d MMMM')}</TypographyH3>
      </div>
      <div className="mt-4 flex flex-col gap-4">{renderTables}</div>
    </SettingsLayout>
  );
};

export const Route = createFileRoute('/_authenticated/home/manage/$date')({
  component: HomeManageAllBookings,
  validateSearch: homeSearchSchema,
  loader: async ({ params, context }) => {
    const { date } = params;
    const data = await context.trpcUtils.schedule.detail.fetch({ date });
    return data;
  },
});
