import { OfficeBookingFormValues } from '@client/features/booking/components/office-booking-form';
import { confirm } from '@client/lib/confirm';
import { yyyy_MM_dd } from '@client/lib/const';
import { trpc } from '@client/lib/trpc';
import {
  AWAY_DATES_DESCRIPTION_MAPPING,
  BookingMethod,
  ChangeUserWorkStatusErrorCode,
  DEFAULT_ERROR_PROFILE,
  ERROR_CODE_PROFILES,
  UserWorkStatusType,
  UserWorkStatusTypeHelper,
} from '@officely/models';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { toast } from 'sonner';

const ERROR_MESSAGE_DEFAULT = 'Something went wrong';

type OnNeighborhoodNotAvailable = () => void;
type OnConflictAwayDates = () => void;

export const useBooking = () => {
  const trpcUtils = trpc.useUtils();

  // mutations
  const bookingOfficeCreateMutation = trpc.booking.office.create.useMutation();
  const bookingOfficeCancelMutation = trpc.booking.office.cancel.useMutation();
  const bookingOfficeInviteMutation = trpc.booking.office.invite.useMutation();
  const workStatusChangeMutation = trpc.workStatus.change.useMutation();
  const workStatusUpdateNoteMutation = trpc.workStatus.updateNote.useMutation();
  const workStatusChangeManyMutation = trpc.workStatus.changeMany.useMutation();
  const workStatusCreateAwayDatesMutation = trpc.workStatus.createAwayDates.useMutation();
  const workStatusUpdateAwayDatesMutation = trpc.workStatus.updateAwayDates.useMutation();
  const workStatusRemoveAwayDatesMutation = trpc.workStatus.removeAwayDates.useMutation();

  const invalidateTRPC = useCallback(async () => {
    return await Promise.all([
      trpcUtils.workStatus.get.invalidate(),
      trpcUtils.schedule.detail.invalidate(),
      trpcUtils.booking.office.availability.invalidate(),
      trpcUtils.workStatus.getAwayDatesForDate.invalidate(),
      trpcUtils.workStatus.getForPeople.invalidate(),
      trpcUtils.booking.office.allAvailability.invalidate(),
    ]);
  }, [trpcUtils]);

  const book = useCallback(
    async (
      data: OfficeBookingFormValues & {
        date: string;
        officeId: string;
        onConflictAwayDates?: OnConflictAwayDates;
        onNeighborhoodNotAvailable?: OnNeighborhoodNotAvailable;
      }
    ): Promise<boolean> => {
      try {
        const errCode = await bookingOfficeCreateMutation.mutateAsync({
          officeId: data.officeId,
          date: data.date,
          nbhId: data.nbh,
          extraIds: data.extras,
          invitedPeopleIds: data.invitedCoworkers,
        });
        if (errCode === ChangeUserWorkStatusErrorCode.ConflictAwayDates) {
          data.onConflictAwayDates?.();
        } else if (
          errCode === ChangeUserWorkStatusErrorCode.NeighborhoodNotAvailable &&
          data.onNeighborhoodNotAvailable
        ) {
          data.onNeighborhoodNotAvailable();
        } else if (errCode) {
          const { title, description } = ERROR_CODE_PROFILES[errCode] ?? DEFAULT_ERROR_PROFILE;
          toast.error(title, {
            description,
          });
        }
        !errCode && void invalidateTRPC();
        return !errCode;
      } catch (_err) {
        const err = _err as Error;
        toast.error(ERROR_MESSAGE_DEFAULT, {
          description: err.message,
        });
        return false;
      }
    },
    [toast, bookingOfficeCreateMutation.mutateAsync]
  );

  const cancel = useCallback(
    async (data: { date: string }) => {
      try {
        const errCode = await bookingOfficeCancelMutation.mutateAsync(data);
        if (errCode) {
          const { title, description } = ERROR_CODE_PROFILES[errCode] ?? DEFAULT_ERROR_PROFILE;
          toast.error(title, {
            description,
          });
        }
        void invalidateTRPC();
      } catch (_err) {
        const err = _err as Error;
        toast.error(ERROR_MESSAGE_DEFAULT, {
          description: err.message,
        });
      }
    },
    [bookingOfficeCancelMutation.mutateAsync, toast]
  );

  const updateNote = useCallback(
    async (data: { date: string; note: string; bookingMethod: BookingMethod }) => {
      const promise = workStatusUpdateNoteMutation.mutateAsync(data);
      toast.promise(promise, {
        loading: 'Updating note...',
        success: (errorCode) => {
          if (errorCode) {
            throw errorCode;
          }
          return 'Note updated';
        },
        error: (err) => {
          const profile = (typeof err === 'string' && ERROR_CODE_PROFILES[err]) ?? DEFAULT_ERROR_PROFILE;
          return `Failed to update note${profile ? `: ${profile.description}` : ''}`;
        },
      });
      const errCode = await promise;
      !errCode && void invalidateTRPC();
    },
    [workStatusUpdateNoteMutation.mutateAsync, toast]
  );

  const inviteCoworkers = useCallback(
    async (data: { date: string; officeId: string; peopleIds: string[]; nbhId?: string }) => {
      const promise = bookingOfficeInviteMutation.mutateAsync(data);
      toast.promise(promise, {
        loading: 'Inviting coworkers...',
        success: 'Coworkers invited',
        error: 'Failed to invite coworkers',
      });
      await promise;
    },
    [bookingOfficeInviteMutation.mutateAsync, toast]
  );

  const changeStatus = useCallback(
    async (data: {
      date: string;
      typeOrOfficeId: string;
      onConflictAwayDates?: OnConflictAwayDates;
      onNeighborhoodNotAvailable?: OnNeighborhoodNotAvailable;
    }): Promise<boolean> => {
      const { date, typeOrOfficeId, onConflictAwayDates, onNeighborhoodNotAvailable } = data;

      if (typeOrOfficeId === UserWorkStatusType.OFFICE) {
        throw new Error('Office ID should not be used as a status not OFFICE');
      }

      const isOfficeId = !UserWorkStatusTypeHelper.isValidStatus(typeOrOfficeId);
      if (isOfficeId) {
        return await book({
          date,
          officeId: typeOrOfficeId,
          onConflictAwayDates,
          onNeighborhoodNotAvailable,
        });
      }

      const errCode = await workStatusChangeMutation.mutateAsync({
        date,
        typeOrOfficeId,
      });

      if (errCode === ChangeUserWorkStatusErrorCode.ConflictAwayDates) {
        onConflictAwayDates?.();
      } else if (errCode) {
        const { title, description } = ERROR_CODE_PROFILES[errCode!] ?? DEFAULT_ERROR_PROFILE;
        toast.error(title, {
          description,
        });
      }

      !errCode && void invalidateTRPC();
      return errCode === undefined;
    },
    [workStatusChangeMutation.mutateAsync, toast]
  );

  const changeStatusMany = useCallback(
    async (data: {
      date: string;
      typeOrOfficeId: string;
      personIds: string[];
      bookingMethod: BookingMethod;
      note?: string;
      nbhId?: string;
      extraIds?: string[];
      dates?: {
        from: string;
        to?: string;
      };
      onConflictAwayDates?: OnConflictAwayDates;
      onNeighborhoodNotAvailable?: OnNeighborhoodNotAvailable;
    }): Promise<boolean> => {
      try {
        const promise = workStatusChangeManyMutation.mutateAsync(data);
        const statusText = data.personIds.length > 1 ? 'Statuses' : 'Status';
        toast.promise(promise, {
          loading: `Changing ${statusText.toLowerCase()}...`,
          success: `${statusText} changed`,
        });
        const errCode = await promise;
        if (errCode) {
          const { title, description } = ERROR_CODE_PROFILES[errCode] ?? DEFAULT_ERROR_PROFILE;
          toast.error(title, {
            description,
          });
        }
        !errCode && void invalidateTRPC();
        return !errCode;
      } catch (_err) {
        const err = _err as Error;
        toast.error(ERROR_MESSAGE_DEFAULT, {
          description: err.message,
        });
        return false;
      }
    },
    [workStatusChangeManyMutation.mutateAsync, toast]
  );

  const createAwayDates = useCallback(
    async (data: { type: UserWorkStatusType; dates: { from: Date; to?: Date }; note?: string }): Promise<boolean> => {
      const { type, dates, note } = data;
      const result = await workStatusCreateAwayDatesMutation.mutateAsync({
        type,
        dates: {
          from: format(dates.from, yyyy_MM_dd),
          to: format(dates.to ?? dates.from, yyyy_MM_dd),
        },
        note,
      });
      if (!result.success) {
        const { title, description } = ERROR_CODE_PROFILES[result.errorCode!] ?? DEFAULT_ERROR_PROFILE;
        toast.error(title, {
          description,
        });
      }
      result.success && void invalidateTRPC();
      return result.success;
    },
    [workStatusCreateAwayDatesMutation.mutateAsync, toast]
  );

  const updateAwayDates = useCallback(
    async (data: { id: string; dates: { from: Date; to: Date }; note?: string }): Promise<boolean> => {
      const { id, dates, note } = data;
      const result = await workStatusUpdateAwayDatesMutation.mutateAsync({
        id,
        note,
        dates: {
          from: format(dates.from, yyyy_MM_dd),
          to: format(dates.to, yyyy_MM_dd),
        },
      });
      if (!result.success) {
        const { title, description } = ERROR_CODE_PROFILES[result.errorCode!] ?? DEFAULT_ERROR_PROFILE;
        toast.error(title, {
          description,
        });
      }
      result.success && void invalidateTRPC();
      return result.success;
    },
    [workStatusUpdateAwayDatesMutation.mutateAsync, toast]
  );

  const removeAwayDates = useCallback(
    async (data: { id: string; type: UserWorkStatusType; skipConfirm?: boolean }): Promise<boolean> => {
      const { id, type, skipConfirm } = data;
      const { cancelText } = AWAY_DATES_DESCRIPTION_MAPPING[type as keyof typeof AWAY_DATES_DESCRIPTION_MAPPING] ?? {
        cancelText: 'Remove Away Dates?',
      };
      if (!skipConfirm) {
        const check = await confirm(`Are you sure you want to cancel?`, {
          title: cancelText,
          ctaText: 'Yes',
          cancelText: 'No',
        });
        if (!check) {
          return false;
        }
      }
      const result = await workStatusRemoveAwayDatesMutation.mutateAsync({
        id,
      });
      if (!result.success) {
        const { title, description } = ERROR_CODE_PROFILES[result.errorCode!] ?? DEFAULT_ERROR_PROFILE;
        toast.error(title, {
          description,
        });
      }
      result.success && void invalidateTRPC();
      return result.success;
    },
    [workStatusRemoveAwayDatesMutation.mutateAsync, toast]
  );

  return {
    book,
    cancel,
    updateNote,
    inviteCoworkers,
    changeStatus,
    changeStatusMany,
    createAwayDates,
    updateAwayDates,
    removeAwayDates,
  };
};
