import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from '@client/lib/utils';
import { Button } from '@client/components/ui/button';
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@client/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@client/components/ui/popover';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRect } from '@radix-ui/react-use-rect';

import type { MultiSelectPrimitiveProps } from './types';
import { useScreenSize } from '@client/lib/useScreenSize';
import { Drawer, DrawerContent, DrawerTrigger } from '@client/components/ui/drawer';
import { DialogScreenReaderInfo } from '@client/components/ui/dialog';

export const MultiSelectPrimitive = (props: MultiSelectPrimitiveProps) => {
  const {
    className,
    options,
    selected,
    onChange,
    placeholder = 'Select items...',
    searchPlaceholder = 'Search...',
    noResultsMessage = 'No results found.',
    searchTerm,
    disabled,
    closeOnSelect,
    loading,
    onSearchChange,
    onOpenChange,
  } = props;
  const [open, setOpen] = useState(false);
  const { isMobile } = useScreenSize();

  const [popoverContent, setPopoverContent] = useState<any>();
  const rect = useRect(popoverContent);
  const selectedValues = useMemo(() => selected.map((opt) => opt.value), [selected]);
  const inputRef = useRef<HTMLInputElement>(null);

  // we need this because the underlying component converts values to lowercase
  const optionValuesMap = useMemo(
    () => Object.fromEntries(options.map((opt) => [opt.value.toLowerCase(), opt.value])),
    [options]
  );

  const removeOption = useCallback(
    (value: string) => {
      onChange(selectedValues.filter((val) => val !== value));
    },
    [selectedValues, onChange]
  );

  const handleSelect = useCallback(
    (_currentValue: string) => {
      const currentValue = optionValuesMap[_currentValue] ?? _currentValue;
      if (selectedValues.includes(currentValue)) {
        removeOption(currentValue);
      } else {
        onChange([...selectedValues, currentValue]);
      }
      if (closeOnSelect) {
        setOpen(false);
      } else {
        inputRef.current?.focus();
      }
    },
    [selectedValues, onChange, optionValuesMap, closeOnSelect, inputRef]
  );

  // sync open state with parent
  useEffect(() => {
    onOpenChange?.(open);
  }, [onOpenChange, open]);

  const renderedButton = useMemo(
    () => (
      <Button
        type="button"
        disabled={disabled}
        variant="outline"
        role="combobox"
        aria-expanded={open}
        className={cn(`w-full h-full justify-between items-center hover:bg-transparent font-normal`, {
          ['py-0 pl-0']: selected.length > 0,
        })}>
        <div className="shrink text-left max-w-full">
          {/* placeholder */}
          <span
            className={cn('max-w-full', {
              hidden: selected.length > 0,
              // ['opacity-0']: selectedOptions.length > 0,
            })}>
            {placeholder}
          </span>
          {/* selected options */}
          {selected.length > 0 && (
            <div className="flex flex-wrap gap-1 p-1 max-w-full">
              {selected.map((opt) => (
                <React.Fragment key={opt.value}>{opt.elem}</React.Fragment>
              ))}
            </div>
          )}
        </div>

        <div className="ml-2">
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </div>
      </Button>
    ),
    [selected, placeholder, disabled, open]
  );

  const renderedCommand = useMemo(
    () => (
      <Command className={`w-full`} shouldFilter={typeof searchTerm !== 'string'}>
        <CommandInput
          ref={inputRef}
          placeholder={searchPlaceholder}
          value={searchTerm}
          onValueChange={onSearchChange}
          autoFocus
          loading={loading}
        />

        <CommandEmpty>{noResultsMessage}</CommandEmpty>
        {/* {!loading && !!searchTerm && <CommandEmpty>{noResultsMessage}</CommandEmpty>} */}

        {/* {options.length === 0 &&
          (loading ? (
            <div className="px-1 py-2">
              <Skeleton className="w-[40%] h-[12px] rounded-sm" />
              <Skeleton className="w-full h-[30px] rounded-md mt-1" />
            </div>
          ) : !searchTerm ? (
            <div className="py-6 text-center text-sm">{noResultsMessage}</div>
          ) : null)} */}

        <CommandList>
          {options.map((opt) => (
            <CommandItem key={opt.value} value={opt.value} onSelect={handleSelect}>
              <Check className={cn('mr-2 h-4 w-4', selectedValues.includes(opt.value) ? 'opacity-100' : 'opacity-0')} />
              {opt.elem}
            </CommandItem>
          ))}
        </CommandList>
      </Command>
    ),
    [options, selectedValues, searchTerm, searchPlaceholder, noResultsMessage, onSearchChange, handleSelect, loading]
  );

  if (isMobile) {
    return (
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild>{renderedButton}</DrawerTrigger>
        <DrawerContent addClose className={`w-[var(--radix-popover-trigger-width)] pb-4`}>
          <DialogScreenReaderInfo title={placeholder} />

          {renderedCommand}
        </DrawerContent>
      </Drawer>
    );
  } else {
    return (
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>{renderedButton}</PopoverTrigger>
        <PopoverContent
          ref={setPopoverContent}
          side="bottom"
          avoidCollisions={false}
          className={`w-[var(--radix-popover-trigger-width)] p-0`}
          style={{
            // width: 300,
            maxHeight: `calc(100vh - ${rect?.top || 0}px)`,
            overflow: 'auto',
          }}>
          {renderedCommand}
        </PopoverContent>
      </Popover>
    );
  }
};
