import { OnboardingState, OnboardingStateUpdate } from '@client/features/onboarding/types';
import { trpc } from '@client/lib/trpc';
import { useCallback, useEffect, useState } from 'react';
import {
  DefaultExtraId,
  DEFAULT_EXTRA_PROFILES,
  OfficeBroadcastType,
  OnboardingFlow,
  ONBOARDING_FLOW_STEPS,
  OnboardingStep,
} from '@officely/models';
import { toast } from 'sonner';
import { confirm } from '@client/lib/confirm';
import { ENABLED_STATUS_TYPES } from '@client/features/onboarding/const';

const LOCAL_STORAGE_KEY = 'onboarding.state';

function getInitialState(args: { requestedFlow?: OnboardingFlow; officeCount: number }): OnboardingState {
  const { requestedFlow, officeCount } = args;
  const flow = requestedFlow ?? (officeCount ? OnboardingFlow.NewUser : OnboardingFlow.Installer);
  const defaultExtras = Object.values(DefaultExtraId).map((id) => ({
    defaultExtraId: id,
    enabled: true, // enable by default
    name: DEFAULT_EXTRA_PROFILES[id].name,
    emoji: DEFAULT_EXTRA_PROFILES[id].emoji,
  }));
  return {
    flow,
    stepIndex: 0,
    // remove broadcast and instead try enable statuses
    steps:
      flow === OnboardingFlow.Installer
        ? [
            OnboardingStep.OfficeCreation,
            OnboardingStep.OfficeNeighbourhoods,
            OnboardingStep.OfficeExtras,
            // OnboardingStep.OfficeBroadcast,
            OnboardingStep.EnableStatuses,
            OnboardingStep.OthersTestingOfficely,
          ]
        : ONBOARDING_FLOW_STEPS[flow],
    // steps: ONBOARDING_FLOW_STEPS[flow],
    submitting: false,
    extras: [OnboardingFlow.Installer, OnboardingFlow.OfficeCreation].includes(flow) ? defaultExtras : undefined,
    enabledStatuses: flow === OnboardingFlow.Installer ? ENABLED_STATUS_TYPES : undefined,
  };
}

export const useOnboarding = (args: {
  flow?: OnboardingFlow;
  officeCount: number;
  onExit?: () => void;
  onSuccess?: () => void;
}) => {
  const trpcUtils = trpc.useUtils();
  const onboardingCompleteMutation = trpc.onboarding.complete.useMutation();

  const [onboardingState, setOnboardingState] = useState<OnboardingState>(() => {
    const initialState = localStorage.getItem(LOCAL_STORAGE_KEY);
    return initialState
      ? JSON.parse(initialState)
      : getInitialState({ requestedFlow: args.flow, officeCount: args.officeCount });
  });

  // Update local storage whenever onboardingState changes
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(onboardingState));
  }, [onboardingState]);

  const successFn = useCallback(() => {
    // clear local storage
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    if (args.flow) {
      // handle office creation flow
      void trpcUtils.directory.office.invalidate();
    }
    args.onSuccess?.();
  }, [args.flow, args.onSuccess]);

  const updateOnboardingState = useCallback(
    (data: OnboardingStateUpdate) => {
      setOnboardingState(() => ({ ...onboardingState, ...data }));
    },
    [onboardingState, setOnboardingState]
  );

  const handleBack = useCallback(() => {
    const isFirstStep = onboardingState.stepIndex === 0;
    if (!isFirstStep) {
      setOnboardingState({ ...onboardingState, stepIndex: onboardingState.stepIndex - 1 });
    }
  }, [onboardingState, setOnboardingState]);

  const handleNext = useCallback(async () => {
    // if not last step, increment step index
    if (onboardingState.stepIndex < onboardingState.steps.length - 1) {
      return setOnboardingState({ ...onboardingState, stepIndex: onboardingState.stepIndex + 1 });
    }

    // otherwise submit onboarding
    const flow = onboardingState.flow;
    const data: Parameters<typeof onboardingCompleteMutation.mutate>[0] =
      flow === OnboardingFlow.NewUser
        ? {
            flow,
            officeId: onboardingState.officeId!,
            favouriteCoworkerPeopleIds: onboardingState.favouriteCoworkerIds ?? [],
          }
        : {
            flow,
            othersTestingOfficelyPeopleIds: onboardingState.othersTestingOfficely ?? [],
            enabledStatuses: onboardingState.enabledStatuses ?? undefined,
            officeData: {
              emoji: onboardingState.officeEmoji!,
              googlePlaceId: onboardingState.googlePlaceId!,
              managerPeopleIds: onboardingState.officeManagerIds ?? [],
              name: onboardingState.officeName!,
              neighbourhoods: onboardingState.neighbourhoods ?? [],
              extras: (onboardingState.extras ?? []).filter((x) => x.enabled),
              broadcast: onboardingState.broadcastChannelId
                ? {
                    teamsChannelId: onboardingState.broadcastChannelId,
                    type: onboardingState.broadcastType ?? OfficeBroadcastType.Tomorrow,
                  }
                : undefined,
            },
          };

    await onboardingCompleteMutation.mutateAsync(data);
    successFn();
  }, [onboardingState, setOnboardingState, onboardingCompleteMutation.mutateAsync, successFn]);

  const handleExit = useCallback(async () => {
    let confirmation = true;
    if (!!onboardingState?.stepIndex) {
      confirmation = await confirm("Are you sure you want to exit? You're progress will be lost.");
    }
    if (confirmation) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      args.onExit?.();
    }
  }, [onboardingState?.stepIndex, args.onExit]);

  useEffect(() => {
    console.log('onboarding.state', onboardingState);
  }, [onboardingState]);

  useEffect(() => {
    if (onboardingCompleteMutation.error) {
      toast.error('We had trouble completing your onboarding. Please try again.');
    }
  }, [onboardingCompleteMutation.error]);

  const allowExit = [OnboardingFlow.OfficeCreation].includes(onboardingState.flow);
  const currentStep = onboardingState.steps[onboardingState.stepIndex];

  return {
    state: onboardingState,
    step: currentStep,
    submitting: onboardingCompleteMutation.isPending,
    update: updateOnboardingState,
    back: handleBack,
    next: handleNext,
    exit: allowExit ? handleExit : undefined,
  };
};
