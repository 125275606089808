export const emojis = {
  wave: '👋',
  office: '🏢',
  neighbourhood: '📬',
  wand: '🪄',
  tada: '🎉',
  close: '⛔️',
  info_text: '💬',
  prohibited: '🚫',
  glowing_star: '🌟',
  calendar: '📅',
  sillohuette: '👤',
  sillohuette_group: '👥',
  star: '⭐️',
  gem: '💎',
  card: '💳',
  old_key: '🗝️',
  face_with_monocle: '🧐',
  lady_bug: '🐞',
  yellow_heart: '💛',
  cold_sweat: '😰',
  studio_microphone: '🎙️',
  white_check_mark: '✅',
  lower_left_fountain_pen: '🖋️',
  heavy_plus_sign: '➕',
  cookie: '🍪',
  clock_9: '🕙',
  clock_3: '🕒',
  mega: '📣',
  ship: '🚢',
  palm_tree: '🌴',
  tropical_drink: '🍹',
  umbrella: '⛱️',
  snow_capped_mountain: '🏔️',
  luggage: '🧳',
  taxi: '🚕',
  plane: '✈️',
  magnifying_glass: '🔍',
  hammer_and_wrench: '🛠️',
} as const;

export const splitTextAndEmojis = (input: string): (string | { emoji: string; isModifier?: boolean })[] => {
  const emojiRegex =
    /([\u{1F3FB}-\u{1F3FF}]|[\u{1F1E6}-\u{1F1FF}][\u{1F1E6}-\u{1F1FF}]|[\u{1F600}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F300}-\u{1F5FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F1E6}-\u{1F1FF}])/gu;

  let result: (string | { emoji: string; isModifier?: boolean })[] = [];
  let lastIndex = 0;

  input.replace(emojiRegex, (...args) => {
    const [match] = args;
    const offset = args.find((arg) => typeof arg === 'number') as number;
    if (lastIndex !== offset) {
      result.push(input.substring(lastIndex, offset));
    }
    const isModifier = match.match(/[\u{1F3FB}-\u{1F3FF}]|\u{200D}|\u{2640}/u) ? true : false;
    result.push({ emoji: match, isModifier });
    lastIndex = offset + match.length;
    return match;
  });

  if (lastIndex !== input.length) {
    result.push(input.substring(lastIndex));
  }
  // console.log(JSON.stringify({ input, result }));
  result = result.map((x) => (typeof x === 'string' ? x.trim() : x));
  // console.log(JSON.stringify({ input, result }));
  return result;
};