/* eslint-disable sonarjs/no-duplicate-string */
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { useCallback, useMemo } from 'react';
import { StepComponentProps } from '@client/features/onboarding/types';
import { UserWorkStatusType, UserWorkStatusTypeHelper } from '@officely/models';
import { MultiSelect } from '@client/components/ui/custom/multi-select';
import { ENABLED_STATUS_TYPES } from '@client/features/onboarding/const';

const CtaBtns = (props: StepComponentProps) => {
  const { onNext, onBack } = props;

  return (
    <div className="mt-6">
      <Button variant="outline" onClick={onBack}>
        Back
      </Button>
      <Button variant="default" className="ml-2" onClick={onNext}>
        Next
      </Button>
    </div>
  );
};

export const EnableStatuses = (props: StepComponentProps) => {
  const { state, updateState } = props;

  const handleChange = useCallback(
    (values: string[]) => {
      updateState({
        enabledStatuses: values as UserWorkStatusType[],
      });
    },
    [updateState]
  );

  const options = useMemo(() => {
    return ENABLED_STATUS_TYPES.map((t) => new UserWorkStatusTypeHelper(t)).map((h) => ({
      value: h.data,
      label: `${h.profile.emoji}  ${h.profile.name}`,
    }));
  }, []);

  const selected = useMemo(() => {
    return options.filter((o) => state.enabledStatuses?.includes(o.value));
  }, [options, state.enabledStatuses]);

  return (
    <div className="mt-2">
      <TypographyP>
        Enable other statuses for better visibility of each employees' working status and location.
      </TypographyP>

      {/* <div className="mt-4 flex flex-row flex-wrap gap-2">
        {STATUS_TYPES.map((t) => new UserWorkStatusTypeHelper(t)).map((helper) => (
          <div key={helper.data} className="flex items-center mt-2 bg-secondary rounded-full px-4 py-2">
            <Emoji lg>{helper.profile.emoji}</Emoji>
            <TypographyP className="ml-2 text-lg text-secondary-foreground italic font-normal whitespace-nowrap overflow-hidden text-ellipsis">
              {helper.profile.name}
            </TypographyP>
          </div>
        ))}
      </div> */}

      <div className="mt-6">
        <MultiSelect
          onChange={handleChange}
          options={options}
          selected={selected}
          optionsAsBtns
          optionsAsBtnsProps={{
            badge: true,
            size: 'default',
          }}
        />
      </div>

      {/* <div className="mt-6 mb-4 flex flex-row items-center gap-2" onClick={handleToggle}>
        <Switch checked={state.enableStatuses} onChange={handleToggle} />
        <TypographyP>Enable other statuses</TypographyP>
      </div> */}

      <CtaBtns {...props} />
    </div>
  );
};
