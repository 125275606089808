/* eslint-disable sonarjs/no-duplicate-string */
import { createFileRoute, Link, Outlet, redirect, useRouter } from '@tanstack/react-router';
import z from 'zod';
import {
  AccountHelper,
  BookingMethod,
  UserWorkStatusType,
  UserWorkStatusTypeHelper,
  yyyyMmDdSchema,
} from '@officely/models';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { Button } from '@client/components/ui/button';
import { Emoji } from '@client/components/ui/custom/emoji';
import { emojis } from '@client/lib/emojis';
import { DefaultLayout } from '@client/components/layout/DefaultLayout';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectOffice } from '@client/components/shared/SelectOffice';
import { ChevronDownIcon, LineChartIcon, MapIcon, PlusIcon, SettingsIcon } from 'lucide-react';
import { trpc } from '@client/lib/trpc';
import { ScheduleView } from '@client/features/home/components/schedule-view';
import {
  DayActionType,
  DayActionTyped,
  HomeModalData,
  OnDayAction,
  SchedulePeriod,
  ScheduleViewOfficeOnly,
  ScheduleViewStatuses,
} from '@client/features/home/types';
import { useBooking } from '@client/features/booking/hooks/use-booking';
import { HomeView } from '@client/features/home/const';
import { toast } from 'sonner';
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@client/components/ui/dialog';
import { cn } from '@client/lib/utils';
import { Switch } from '@client/components/ui/switch';
import { useScreenSize } from '@client/lib/useScreenSize';
import { Modal } from '@client/components/ui/custom/modal';
import { NotesModal } from '@client/features/booking/components/notes-modal';
import { DetailsModal } from '@client/features/home/components/details-modal';
import { OfficeBookingModal } from '@client/features/booking/components/office-booking-modal';
import { AwayDatesModal } from '@client/features/booking/components/away-dates-modal';
import { AnnouncementListModal } from '@client/features/announcements/components/list-modal';
import { OfficeDayInviteModal } from '@client/features/booking/components/office-day-invite-modal';
import { OfficelyInviteModal } from '@client/features/app/components/officely-invite-modal';
import { FeedbackModal } from '@client/features/app/components/feedback-modal';
import { dayActionToHomeModal } from '@client/features/home/helpers';
import { AnnouncementCreateModal } from '@client/features/announcements/components/create-modal';
import { isDemoMode } from '@client/lib/auth';
import { ManageStatusesModal } from '@client/features/booking/components/manage-statuses-modal';
import { homeSearchSchema } from '@client/features/home/schemas';

// --------- HOOKS --------

const useHomeModal = () => {
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<HomeModalData | null>(null);

  const openModal = useCallback((args: HomeModalData) => {
    setModalData(args);
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleModalDone = useCallback(async () => {
    await router.invalidate();
  }, [router.invalidate]);

  return {
    modalOpen,
    modalData,
    openModal,
    onModalClose: handleModalClose,
    onModalDone: handleModalDone,
  };
};

const useAction = (props: { openModal: (args: HomeModalData) => void }) => {
  const { openModal } = props;
  const router = useRouter();
  const { cancel, book, changeStatus } = useBooking();
  const { office, lazyBookingEnabled } = Route.useLoaderData();
  const navigate = useNavigate({ from: Route.fullPath });

  const handleChangeStatus = useCallback(
    async (payload: DayActionTyped<DayActionType.ChangeStatus>) => {
      const { date } = payload;
      let typeOrOfficeId = payload.statusOrOfficeId;
      // check if they are trying to change to an away date
      const { isAwayDate } = new UserWorkStatusTypeHelper(typeOrOfficeId as any);
      if (isAwayDate) {
        return openModal({
          type: 'away-dates',
          date,
          awayDatesType: typeOrOfficeId as UserWorkStatusType,
        });
      }

      // If the user has selected the office status, we need to use the office ID
      if (typeOrOfficeId === UserWorkStatusType.OFFICE) {
        typeOrOfficeId = office.id;
      }

      const result = await changeStatus({
        date,
        typeOrOfficeId,
        onConflictAwayDates: () =>
          openModal({
            type: 'away-dates',
            date,
            conflictWith: typeOrOfficeId as UserWorkStatusType,
          }),
        onNeighborhoodNotAvailable: () =>
          openModal({
            type: 'book',
            date,
            officeId: typeOrOfficeId,
          }),
      });

      if (result) {
        await router.invalidate();
      }

      if (typeOrOfficeId === UserWorkStatusType.CLIENT_OFFICE) {
        openModal({
          type: 'notes',
          date,
          bookingMethod: BookingMethod.APP_HOME,
        });
      }
    },
    [changeStatus, openModal, office, router.invalidate]
  );

  const handleJoinDay = useCallback(
    async (data: DayActionTyped<DayActionType.JoinDay>) => {
      const { date, officeId } = data;
      if (!lazyBookingEnabled) {
        return openModal({
          type: 'book',
          date,
          officeId,
        });
      }
      const success = await book({
        date,
        officeId,
        onConflictAwayDates: () =>
          openModal({
            type: 'away-dates',
            date,
          }),
        onNeighborhoodNotAvailable: () =>
          openModal({
            type: 'book',
            date,
            officeId,
          }),
      });
      if (success) {
        await router.invalidate();
      }
    },
    [lazyBookingEnabled, book, openModal, router.invalidate]
  );

  const onDayAction = useCallback<OnDayAction>(
    async (payload) => {
      switch (payload.type) {
        case DayActionType.ChangeNote:
        case DayActionType.ModifyBooking:
        case DayActionType.SeeDetails:
        case DayActionType.ManageAnnouncements:
        case DayActionType.CreateAnnouncement:
        case DayActionType.ChangeAwayDates:
        case DayActionType.InviteCoworkers:
          const modalData = dayActionToHomeModal(payload);
          return openModal(modalData!);
        case DayActionType.ManageStatuses:
          return openModal({
            type: 'manage-statuses',
            date: payload.date,
          });
        // return navigate({
        //   to: '/home/manage/$date',
        //   params: { date: payload.date },
        //   search: (prev) => ({ ...prev }),
        // });
        case DayActionType.CancelDay:
          await cancel({ date: payload.date });
          await router.invalidate();
          break;
        case DayActionType.ChangeStatus:
          return handleChangeStatus(payload);
        case DayActionType.JoinDay:
          return handleJoinDay(payload);
      }
    },
    [openModal, cancel, handleChangeStatus, handleJoinDay, router.invalidate, navigate]
  );

  return onDayAction;
};

// ---------- COMPONENTS ----------

function HomeModal(
  props: HomeModalData & {
    onClose: () => void;
    onDone: () => void;
  }
) {
  const { onClose, onDone } = props;
  const commonProps = {
    onClose,
    onDone,
  };

  if (props.type === 'notes') {
    return <NotesModal {...commonProps} date={props.date} bookingMethod={props.bookingMethod} />;
  }
  if (props.type === 'details') {
    return <DetailsModal {...commonProps} date={props.date} initialFilters={props.initialFilters} />;
  }
  if (props.type === 'book') {
    return <OfficeBookingModal {...commonProps} date={props.date} officeId={props.officeId} />;
  }
  if (props.type === 'away-dates') {
    return (
      <AwayDatesModal
        {...commonProps}
        date={props.date}
        awayDatesType={props.awayDatesType}
        conflictWith={props.conflictWith}
      />
    );
  }
  if (props.type === 'create-announcement') {
    return <AnnouncementCreateModal {...commonProps} date={props.date} officeId={props.officeId} />;
  }
  if (props.type === 'announcements') {
    return <AnnouncementListModal {...commonProps} date={props.date} officeId={props.officeId} id={props.id} />;
  }
  if (props.type === 'office-day-invite') {
    return <OfficeDayInviteModal {...commonProps} date={props.date} officeId={props.officeId} nbhId={props.nbhId} />;
  }
  if (props.type === 'officely-invite') {
    return <OfficelyInviteModal {...commonProps} />;
  }
  if (props.type === 'feedback') {
    return <FeedbackModal {...commonProps} userName={props.userName} />;
  }
  if (props.type === 'manage-statuses') {
    return <ManageStatusesModal {...commonProps} date={props.date} />;
  }
  return null;
}

function LoadDemoBtn() {
  const demoMode = useMemo(() => isDemoMode(), []);
  if (demoMode) {
    const router = useRouter();
    const loadDemoMutation = trpc.account.loadDemo.useMutation();
    const handleLoadDemo = useCallback(async () => {
      const promise = loadDemoMutation.mutateAsync();
      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Demo loaded',
        error: 'Failed to load demo',
      });
      await promise;
      await router.invalidate();
    }, [loadDemoMutation, router]);
    return (
      <Button variant="ghost" onClick={handleLoadDemo}>
        Load Demo
      </Button>
    );
  }
  return null;
}

function FloorPlanModal(props: { open: boolean; onClose: () => void }) {
  const { office } = Route.useLoaderData();
  const {
    floorPlan: { url, isImage },
  } = office;
  if (!url || !isImage) return null;
  return (
    <Dialog open={props.open} onOpenChange={props.onClose}>
      <DialogContent className="max-w-2xl">
        <DialogTitle>Floor Plan</DialogTitle>
        <DialogDescription>{url}</DialogDescription>
        <img src={url} alt="Floor Plan" />
        <Button variant="link" asChild>
          <a href={url} target="_blank">
            Open in new tab
          </a>
        </Button>
      </DialogContent>
    </Dialog>
  );
}

function Header(props: { openModal: (args: HomeModalData) => void }) {
  const searchParams = Route.useSearch();
  const { view, isAccountAdmin, isOfficeAdmin, officeCount, office, userOfficeId, statusesEnabled } =
    Route.useLoaderData();
  const isAnyAdmin = isAccountAdmin || isOfficeAdmin;

  const authAuthCodeQuery = trpc.auth.authCode.useQuery();
  const authCode = authAuthCodeQuery.data ?? '';
  const navigate = useNavigate({ from: Route.fullPath });
  const [floorPlanOpen, setFloorPlanOpen] = useState(false);
  const { isMobile } = useScreenSize();

  const handleOfficeChange = useCallback(
    async (office: string | undefined) => {
      await navigate({
        to: '/home',
        replace: true,
        search: (prev) => ({
          ...prev,
          office,
        }),
      });
    },
    [navigate]
  );

  const handleViewChange = useCallback(
    (value: string | undefined) => {
      void navigate({
        to: '/home',
        replace: true,
        search: (prev) => ({
          ...prev,
          view: value as HomeView,
        }),
      });
    },
    [navigate]
  );

  // clear the office after the user returns to the default
  useEffect(() => {
    if (searchParams.office === userOfficeId) {
      void navigate({
        to: '/home',
        replace: true,
        search: (prev) => ({
          ...prev,
          office: undefined,
        }),
      });
    }
  }, [searchParams.office, userOfficeId, navigate]);

  const handleHideStatuses = useCallback(
    async (value: boolean) => {
      const nextView = value ? HomeView.OfficeOnly : HomeView.Default;
      await handleViewChange(nextView);
    },
    [handleViewChange]
  );

  const handleOfficelyInvite = useCallback(() => {
    props.openModal({
      type: 'officely-invite',
    });
  }, [props.openModal]);

  const showingStatuses = view === HomeView.Default;

  const renderedSwitch = useMemo(() => {
    return !statusesEnabled ? null : (
      <div
        className={cn('flex items-center', {
          // mobile styles
          'flex-row-reverse': isMobile,
          'my-4': isMobile,
          'justify-end': isMobile,

          // desktop styles
          'space-x-2': !isMobile,
          'ml-auto': !isMobile,
        })}>
        <TypographyP
          className={cn('text-xs font-bold text-muted-foreground', {
            'ml-2': isMobile,
          })}>
          Office Only
        </TypographyP>
        <Switch checked={!showingStatuses} onCheckedChange={handleHideStatuses} id="office-only-switch" />
      </div>
    );
  }, [showingStatuses, handleHideStatuses, isMobile, statusesEnabled]);

  return (
    <div className="mb-8">
      <FloorPlanModal open={floorPlanOpen} onClose={() => setFloorPlanOpen(false)} />
      <div className="flex items-center mb-4">
        <Emoji lg className="mr-2">
          {office.emoji}
        </Emoji>
        <TypographyP className="font-semibold">{office.name}</TypographyP>
        {officeCount > 1 && (
          <div className="ml-2">
            <SelectOffice
              value={office.id}
              onChange={handleOfficeChange}
              placeholderIcon={<ChevronDownIcon className="w-4 h-4" />}
              placeholder=""
              asBtn
              asBtnProps={{ variant: 'ghost', size: 'icon' }}
            />
          </div>
        )}
      </div>

      <ButtonGroup className="mt-4">
        <Button variant={'outline'} onClick={handleOfficelyInvite}>
          <PlusIcon className="w-4 h-4 mr-2" />
          {isAnyAdmin ? 'Employees' : 'Coworkers'}
        </Button>

        {isAnyAdmin && (
          <Button variant={'outline'} asChild>
            <Link to={`/insights-redirect?code=${authCode}`} target="_blank">
              <LineChartIcon className="w-4 h-4 mr-2" />
              Insights
            </Link>
          </Button>
        )}

        {office.floorPlan.url &&
          (office.floorPlan.isImage ? (
            <Button variant={'outline'} onClick={() => setFloorPlanOpen(true)}>
              <MapIcon className="w-4 h-4 mr-2" />
              Floor Plan
            </Button>
          ) : (
            <Button variant={'outline'} asChild>
              <a href={office.floorPlan.url} target="_blank">
                <MapIcon className="w-4 h-4 mr-2" />
                Floor Plan
              </a>
            </Button>
          ))}

        <Button variant={'outline'} asChild>
          <Link to="/home/settings" search={searchParams}>
            <SettingsIcon className="w-4 h-4 mr-2" />
            Settings
          </Link>
        </Button>

        {!isMobile && renderedSwitch}
      </ButtonGroup>

      {isMobile && renderedSwitch}
    </div>
  );
}

function Footer(props: { openModal: (args: HomeModalData) => void }) {
  const { userName } = Route.useLoaderData();
  const handleFeedback = useCallback(() => {
    props.openModal({
      type: 'feedback',
      userName,
    });
  }, [props.openModal, userName]);
  return (
    <div>
      <ButtonGroup className="mt-4">
        <Button variant={'outline'} onClick={handleFeedback}>
          <Emoji className="mr-2">{emojis.gem}</Emoji>
          Suggest A Feature
        </Button>
        <LoadDemoBtn />
      </ButtonGroup>
    </div>
  );
}

function BodyOfficeOnly(props: { schedule: ScheduleViewOfficeOnly; openModal: (args: HomeModalData) => void }) {
  const navigate = useNavigate({ from: Route.fullPath });
  const search = Route.useSearch();
  const openModal = props.openModal;
  const { periodPrev } = props.schedule;

  const onDayAction = useAction({
    openModal,
  });

  const handleChangePeriod = useCallback(
    async (value: SchedulePeriod) => {
      await navigate({
        to: '/home',
        replace: true,
        search: (prev) => ({
          ...prev,
          period: value,
        }),
      });
    },
    [navigate, periodPrev]
  );

  // clear the period after the user returns to the default
  useEffect(() => {
    if (!periodPrev && search.period) {
      void navigate({
        to: '/home',
        replace: true,
        search: (prev) => ({
          ...prev,
          period: undefined,
        }),
      });
    }
  }, [periodPrev, search.period, navigate]);

  return <ScheduleView {...props.schedule} onChangePeriod={handleChangePeriod} onDayAction={onDayAction} />;
}

function BodyDefault(props: { schedule: ScheduleViewStatuses; openModal: (args: HomeModalData) => void }) {
  const navigate = useNavigate({ from: Route.fullPath });
  const search = Route.useSearch();
  const { schedule, openModal } = props;
  const { periodPrev } = schedule;
  const onDayAction = useAction({
    openModal,
  });

  const handleChangePeriod = useCallback(
    async (value: SchedulePeriod) => {
      await navigate({
        to: '/home',
        replace: true,
        search: (prev) => ({
          ...prev,
          period: value,
        }),
      });
    },
    [navigate, periodPrev]
  );

  // clear the period after the user returns to the default
  useEffect(() => {
    if (!periodPrev && search.period) {
      void navigate({
        to: '/home',
        replace: true,
        search: (prev) => ({
          ...prev,
          period: undefined,
        }),
      });
    }
  }, [periodPrev, search.period, navigate]);

  return <ScheduleView {...props.schedule} onChangePeriod={handleChangePeriod} onDayAction={onDayAction} />;
}

function Body(props: { openModal: (args: HomeModalData) => void }) {
  const { view, schedule } = Route.useLoaderData();
  if (view === HomeView.Default) {
    return <BodyDefault schedule={schedule} openModal={props.openModal} />;
  }
  return <BodyOfficeOnly schedule={schedule} openModal={props.openModal} />;
}

function ManagerPanel() {
  return null;
  // <div>
  //   <Sheet open>
  //     <SheetContent>
  //       <TypographyH2>Manager Panel</TypographyH2>
  //     </SheetContent>
  //   </Sheet>
  // </div>
}

function Home() {
  const { modalOpen, modalData, openModal, onModalClose, onModalDone } = useHomeModal();

  return (
    <DefaultLayout>
      <Header openModal={openModal} />
      <Body openModal={openModal} />
      <Footer openModal={openModal} />
      <ManagerPanel />
      <Outlet />
      <Modal open={modalOpen} onClose={onModalClose}>
        {modalData && <HomeModal onClose={onModalClose} onDone={onModalDone} {...modalData} />}
      </Modal>
    </DefaultLayout>
  );
}

export const Route = createFileRoute('/_authenticated/home')({
  component: Home,
  // staleTime: 1000 * 60 * 1, // 1 minute
  validateSearch: homeSearchSchema,
  loaderDeps: ({ search }) => ({
    officeId: search.office,
    period: search.period,
    view: search.view,
  }),
  beforeLoad: async ({ context, search }) => {
    const [account, user] = await Promise.all([
      context.trpcUtils.account.current.fetch(),
      context.trpcUtils.user.current.fetch(),
    ]);
    const licenced = new AccountHelper(account).userIsLicenced(user.id);
    const statusesEnabled = new AccountHelper(account).statusesAreEnabled();

    // persist the view in local storage so that it doesn't get reset when the user returns to the home page
    if (!search.view) {
      const viewFromLocalStorage = localStorage.getItem('home.view');
      if (viewFromLocalStorage) {
        redirect({
          to: '/home',
          search: {
            view: viewFromLocalStorage as HomeView,
          },
          throw: true,
        });
      }
    } else {
      localStorage.setItem('home.view', search.view);
    }

    if (!statusesEnabled && search.view !== HomeView.OfficeOnly) {
      redirect({
        to: '/home',
        search: {
          view: HomeView.OfficeOnly,
        },
        throw: true,
      });
    }

    if (!user.onboardedAt || !user.officeIds.length) {
      redirect({
        to: '/setup',
        throw: true,
      });
    }
    if (!licenced) {
      redirect({
        to: '/upgrade',
        throw: true,
      });
    }
    return {
      ...context,
      statusesEnabled,
      account,
      user,
    };
  },
  loader: async ({ deps, context }) => {
    const { view = HomeView.Default } = deps;
    const { user, account, statusesEnabled } = context;
    const userName = user.profile.name;

    if (view === HomeView.Default) {
      const userOfficeId = user.officeIds[0];
      const officeId = deps?.officeId ?? userOfficeId;
      const [schedule, office, officeCount] = await Promise.all([
        context.trpcUtils.schedule.statuses.fetch({
          officeId,
          period: deps?.period,
        }),
        context.trpcUtils.office.getById.fetch(officeId),
        context.trpcUtils.office.getOfficeCount.fetch(),
      ]);

      const isOfficeAdmin = office.managerIds.includes(user.id);
      const isAccountAdmin = account.managers.includes(user.id);

      return {
        view,
        schedule,
        userName,
        userOfficeId,
        statusesEnabled,
        office: {
          id: office.id,
          name: office.name,
          emoji: office.emoji,
          floorPlan: office.floorPlan,
        },
        officeCount,
        isAccountAdmin,
        isOfficeAdmin,
      };
    }

    const userOfficeId = user.officeIds[0];
    const officeId = deps?.officeId ?? userOfficeId;
    const [schedule, office, officeCount] = await Promise.all([
      context.trpcUtils.schedule.office.fetch({
        officeId,
        period: deps?.period,
      }),
      context.trpcUtils.office.getById.fetch(officeId),
      context.trpcUtils.office.getOfficeCount.fetch(),
    ]);

    const isOfficeAdmin = office.managerIds.includes(user.id);
    const isAccountAdmin = account.managers.includes(user.id);
    const lazyBookingEnabled = user.lazyBookings.enabled;

    return {
      view,
      schedule,
      userName,
      lazyBookingEnabled,
      userOfficeId,
      statusesEnabled,
      office: {
        id: office.id,
        name: office.name,
        emoji: office.emoji,
        floorPlan: office.floorPlan,
      },
      officeCount,
      isAccountAdmin,
      isOfficeAdmin,
    };
  },
});
