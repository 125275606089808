import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { OfficeBookingForm, OfficeBookingFormValues } from '@client/features/booking/components/office-booking-form';
import { useBooking } from '@client/features/booking/hooks/use-booking';
import { yyyy_MM_dd } from '@client/lib/const';
import { CommonPropsForModal, useSmoothLoad } from '@client/lib/modal';
import { trpc } from '@client/lib/trpc';
import { format, parse } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';

type Props = CommonPropsForModal & {
  date: string;
  officeId: string;
  nbhId?: string;
};

export const OfficeBookingModal = (props: Props) => {
  const { date, officeId, nbhId, onDone, onClose, ...restForModal } = props;

  const bookingOfficeAvailability = trpc.booking.office.availability.useQuery({
    date,
    officeId,
  });

  const {
    existingBooking,
    suggestedBooking: _suggestedBooking,
    nbhs = [],
    extras = [],
    officeNbhCount = 0,
  } = bookingOfficeAvailability.data ?? {};

  const suggestedBooking = useMemo(() => {
    const suggestedNbhFromSearchIsAvailable = !!nbhId && !!nbhs?.some((x) => x.id === nbhId);
    const suggestedNbhId = suggestedNbhFromSearchIsAvailable ? nbhId : _suggestedBooking?.nbhId;
    return suggestedNbhId
      ? {
          ..._suggestedBooking,
          nbhId: suggestedNbhId,
        }
      : _suggestedBooking;
  }, [_suggestedBooking, nbhId, nbhs]);

  const { book } = useBooking();

  const handleSubmit = useCallback(
    async (values: OfficeBookingFormValues) => {
      await book({ date, officeId, ...values });
      onDone?.();
      onClose?.();
    },
    [book, onDone, onClose, date, officeId]
  );

  const commonModalProps: ModalLayoutProps = {
    title: format(parse(date, yyyy_MM_dd, new Date()), 'EEEE - d MMM'),
    loading: bookingOfficeAvailability.isLoading,
    submitFormId: OfficeBookingForm.id,
    submitText: existingBooking ? 'Update' : 'Book',
    closeText: props.nested ? 'Back' : 'Cancel',
    onClose,
    ...restForModal,
  };

  const data = useMemo(() => {
    return {
      nbh: existingBooking?.nbhId ?? suggestedBooking?.nbhId ?? nbhs?.[0]?.id,
      extras: existingBooking?.extraIds ?? suggestedBooking?.extraIds,
    };
  }, [existingBooking, suggestedBooking, nbhs]);

  return (
    <ModalLayout {...commonModalProps}>
      <OfficeBookingForm
        onSubmit={handleSubmit}
        extras={extras}
        nbhs={nbhs}
        officeNbhCount={officeNbhCount}
        data={data}
        isModifying={!!existingBooking}
      />
    </ModalLayout>
  );
};
