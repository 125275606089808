import { SimpleForm } from '@client/components/shared/simple-form';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { TextAndEmojis } from '@client/components/ui/custom/text-and-emojis';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { useBooking } from '@client/features/booking/hooks/use-booking';
import { defineForm } from '@client/lib/form/helpers';
import { FormValues } from '@client/lib/form/types';
import { CommonPropsForModal } from '@client/lib/modal';
import { useCallback, useMemo } from 'react';

type Props = CommonPropsForModal & {
  date: string;
  officeId: string;
  nbhId?: string;
};

// funny quips which suggest it's better to go to the office which other people than by yourself
const FUNNY_QUIPS = [
  'The watercooler moment is just so much cooler IRL  💧',
  'Ping pong battles are better in person  🏓',
  'Office gossip hits different in person  🙊',
  'Syncronize your squad  👯‍♀️',
  "You can't form a friendship with with a thumbnail...  👬",
  "It's not a team lunch if it's just you in the kitchen  🍕",
  "Don't you want to know how tall your boss is?  😉",
];

const formDef = defineForm({
  id: 'office-day-invite-form',
  fields: {
    coworkers: {
      type: 'multi-select-people',
      placeholder: 'Select coworkers',
      // description: 'Invite your coworkers to join you in the office',
    },
  },
});

export const OfficeDayInviteModal = (props: Props) => {
  const { date, officeId, nbhId, onDone, onClose, ...restForModal } = props;

  const { inviteCoworkers } = useBooking();

  const handleSubmit = useCallback(
    async (values: FormValues<typeof formDef>) => {
      if (values.coworkers?.length) {
        await inviteCoworkers({ date, officeId, peopleIds: values.coworkers, nbhId });
      }
      onDone?.();
      onClose?.();
    },
    [inviteCoworkers, onDone, onClose, date, officeId, nbhId]
  );

  const commonModalProps: ModalLayoutProps = {
    title: 'Invite Coworkers',
    loading: false,
    submitFormId: formDef.id,
    submitText: 'Invite',
    closeText: 'Cancel',
    onClose,
    ...restForModal,
  };

  const quip = useMemo(() => {
    return FUNNY_QUIPS[Math.floor(Math.random() * FUNNY_QUIPS.length)];
  }, []);

  return (
    <ModalLayout {...commonModalProps}>
      <TypographyP className="mb-2">Invite your coworkers to join you in the office</TypographyP>
      <SimpleForm definition={formDef} onSubmit={handleSubmit} />
      <TextAndEmojis convertEmojis italic className="text-sm text-muted-foreground mt-2">
        {quip}
      </TextAndEmojis>
    </ModalLayout>
  );
};
