import { Button } from '@client/components/ui/button';
import { SelectDateRangeProps } from './types';
import { Drawer, DrawerContent, DrawerTrigger } from '@client/components/ui/drawer';
import { FormControl } from '@client/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@client/components/ui/popover';
import { useScreenSize } from '@client/lib/useScreenSize';
import { cn } from '@client/lib/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { CalendarIcon, Loader2 } from 'lucide-react';
import { Calendar } from '@client/components/ui/calendar';
import { resolveMask } from '@client/components/ui/custom/select-date-range/helpers';
import { DateRange } from 'react-day-picker';
import { DialogScreenReaderInfo } from '@client/components/ui/dialog';

export const SelectDateRange = (props: SelectDateRangeProps) => {
  const {
    selected,
    placeholder = 'Select a date',
    placeholderIcon,
    loading = false,
    asBtn = false,
    asBtnProps = {
      variant: 'default',
      size: props.placeholder ? 'default' : 'icon',
    },
    disabled = false,
    inForm = false,
    allowClear = true,
    allowSameDayRange = false,
    // allowWeekdays = true,
    disableRange = false,
    closeOnChange = false,
    placeholderIconPosition = 'left',
    onChange,
    mask,
  } = props;

  const { isMobile } = useScreenSize();
  const [open, setOpen] = useState(false);

  const handleDateChange = useCallback(
    (value: Date | undefined) => {
      onChange?.({
        from: value ?? undefined,
        to: undefined,
      });
      if (closeOnChange) {
        setOpen(false);
      }
    },
    [onChange, closeOnChange]
  );

  const handleDateRangeChange = useCallback(
    (value: DateRange | undefined) => {
      console.log(value, 'DateRange | undefined');
      const f = value?.from?.toISOString();
      const t = value?.to?.toISOString();
      if (!allowSameDayRange && f === t && value) {
        value.to = undefined;
        value.from = undefined;
      }
      onChange?.({
        from: value?.from ?? undefined,
        to: value?.to ?? undefined,
      });
      if (value?.from && value?.to && closeOnChange) {
        setOpen(false);
      }
    },
    [onChange, closeOnChange, allowSameDayRange]
  );

  // const handleWeekdayClick = useCallback((day: number) => {
  //   setWeekdays((prev) => {
  //     if (prev.includes(day)) {
  //       return prev.filter((d) => d !== day);
  //     } else {
  //       return [...prev, day];
  //     }
  //   });
  // }, []);

  const renderedButton = useMemo(() => {
    // is inForm, wrap in FormControl, otherwise wrap in Button
    const btn = asBtn ? (
      <Button {...asBtnProps} disabled={disabled}>
        {placeholderIconPosition === 'left' && placeholderIcon}
        {placeholder}
        {placeholderIconPosition === 'right' && placeholderIcon}
      </Button>
    ) : (
      // this style makes it look like a normal select (the default)
      <Button
        variant={'outline'}
        disabled={disabled}
        className={cn('w-full h-full justify-start text-left font-normal', !selected?.from && 'text-muted-foreground')}>
        {loading ? (
          <Loader2 className="mr-2 h-4 w-4 shrink-0 opacity-50 animate-spin" />
        ) : (
          <CalendarIcon className="mr-2 h-4 w-4" />
        )}
        {selected?.from ? (
          selected?.to ? (
            <>
              {format(selected.from, 'LLL dd, y')} - {format(selected.to, 'LLL dd, y')}
            </>
          ) : (
            format(selected.from, 'LLL dd, y')
          )
        ) : (
          <span>{placeholder}</span>
        )}
      </Button>
    );
    if (inForm) {
      return <FormControl>{btn}</FormControl>;
    } else {
      return btn;
    }
  }, [selected, placeholder, inForm, asBtn, asBtnProps]);

  const disabledFn = useCallback(
    (d: Date) => {
      const shouldShowDate = !mask || resolveMask(d, mask);
      return disabled || !shouldShowDate;
    },
    [disabled, mask]
  );

  // const renderedFooter = useMemo(() => {
  //   return dateRange.from && dateRange.to && allowWeekdays ? (
  //     <div className="pb-4 px-4">
  //       {/* <Separator className="my-4" /> */}
  //       <TypographyP className="text-xs text-muted-foreground font-bold mb-3 pl-2 mt-4">Days of the week</TypographyP>
  //       <div className="flex flex-wrap gap-2">
  //         {[7, 1, 2, 3, 4, 5, 6].map((day) => (
  //           <Button
  //             key={day}
  //             variant={weekdays.includes(day) ? 'default' : 'ghost'}
  //             size="icon"
  //             className={cn('rounded-full text-xs h-8 w-8', {
  //               'bg-accent text-accent-foreground': !weekdays.includes(day),
  //               // 'bg-primary text-primary-foreground': weekdays.includes(day),
  //             })}
  //             onClick={() => handleWeekdayClick(day)}>
  //             {isoWeekdayToString(day, 'M')}
  //           </Button>
  //         ))}
  //       </div>
  //     </div>
  //   ) : null;
  // }, [dateRange, allowWeekdays, weekdays]);

  const renderedCalendar = useMemo(() => {
    return (
      <div className="w-full flex flex-col justify-center">
        {!disableRange ? (
          <Calendar
            mode="range"
            selected={selected}
            onSelect={handleDateRangeChange}
            initialFocus
            disabled={disabledFn}
            // className="w-full"
          />
        ) : (
          <Calendar
            mode="single"
            selected={selected?.from}
            onSelect={handleDateChange}
            initialFocus
            disabled={disabledFn}
            // className="w-full"
          />
        )}

        {/* {renderedFooter} */}
      </div>
    );
  }, [selected, handleDateRangeChange, disabledFn, disableRange]);

  if (isMobile) {
    return (
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild>{renderedButton}</DrawerTrigger>
        <DrawerContent addClose className={`pb-12`} title="Select Date">
          <DialogScreenReaderInfo title={placeholder} />

          {renderedCalendar}
        </DrawerContent>
      </Drawer>
    );
  } else {
    return (
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>{renderedButton}</PopoverTrigger>
        <PopoverContent
          align="start"
          side="bottom"
          avoidCollisions={true}
          className="w-auto p-0"
          // className="flex w-auto flex-col space-y-2 p-2"
          // className={asBtn ? 'min-w-[200px] p-0' : `w-[var(--radix-popover-trigger-width)] p-0`}
        >
          {renderedCalendar}
        </PopoverContent>
      </Popover>
    );
  }
};
