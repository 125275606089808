import { createFileRoute } from '@tanstack/react-router';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { Modal } from '@client/components/ui/custom/modal';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { TypographyH3 } from '@client/components/ui/custom/typography-h3';
import { Button } from '@client/components/ui/button';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { SendIcon } from 'lucide-react';
import { useBroadcastSettings } from '@client/features/settings/hooks/use-broadcast-settings';
import { BroadcastFormValues } from '@client/features/settings/components/broadcast-form';
import { BroadcastModal } from '@client/features/settings/components/broadcast-modal';
import { useDirectoryContext } from '@client/context/directory';
import { toast } from 'sonner';

function SettingsOfficesOfficeBroadcast() {
  const params = Route.useParams();
  const { office } = Route.useLoaderData();
  const { broadcast } = office;

  const { channels } = useDirectoryContext();
  const { updateBroadcast, disableBroadcast, sendTestBroadcast } = useBroadcastSettings({ officeId: params.id });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const router = useRouter();

  const editData = useMemo<BroadcastFormValues | undefined>(() => {
    return {
      channel: broadcast.teamsChannelId ?? undefined,
      enabled: broadcast.enabled,
      sendTime: broadcast.type,
    };
  }, [broadcast]);

  const handleSubmit = useCallback(
    async (values: BroadcastFormValues) => {
      const channel = values.channel ? channels.data?.[values.channel] : undefined;
      if (!channel) {
        toast.error('Channel not found');
        return;
      }
      await updateBroadcast({
        ...values,
        channel: {
          teamsUniqueId: channel.id,
          msTeamId: channel.teamId,
        },
      });
      setModalOpen(false);
      await router.invalidate();
    },
    [updateBroadcast, setModalOpen, router]
  );

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleDisable = useCallback(async () => {
    await disableBroadcast();
    await router.invalidate();
  }, [disableBroadcast, router]);

  return (
    <div>
      <TypographyH3>Broadcast</TypographyH3>
      <>
        <TypographyP className="mt-2">
          Posts to a channel who's in the office each day along with any announcements. <i>(Highly recommended)</i>
        </TypographyP>
        <img
          className="mt-4 w-full max-w-[492px]"
          src="https://cdn.getofficely.com/assets/Office_Broadcast.png"
          alt="Office broadcast"
        />
      </>

      <ButtonGroup className="mt-4">
        <Button onClick={() => setModalOpen(true)} variant="default">
          {broadcast.enabled ? 'Configure' : 'Enable'}
        </Button>

        {broadcast.enabled && (
          <>
            <Button variant={'outline'} onClick={sendTestBroadcast}>
              Send Test
              <SendIcon className="w-4 h-4 ml-2" />
            </Button>
            <Button variant={'ghost'} onClick={handleDisable}>
              Disable
            </Button>
          </>
        )}
      </ButtonGroup>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <BroadcastModal onSubmit={handleSubmit} data={editData} onClose={handleModalClose} />
      </Modal>
    </div>
  );
}

export const Route = createFileRoute('/_authenticated/home/settings/offices/$id/broadcast')({
  component: SettingsOfficesOfficeBroadcast,
  loader: async ({ context, params }) => {
    const office = await context.trpcUtils.office.getById.fetch(params.id);
    return { office };
  },
});
