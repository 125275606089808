import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { AwayDatesForm, AwayDatesFormValues } from '@client/features/booking/components/away-dates-form';
import { CommonPropsForModal } from '@client/lib/modal';
import { titleCase } from '@client/lib/utils';
import { AWAY_DATES_DESCRIPTION_MAPPING, UserWorkStatusType, UserWorkStatusTypeHelper } from '@officely/models';
import { useCallback, useMemo } from 'react';
import { parse } from 'date-fns';
import { yyyy_MM_dd } from '@client/lib/const';
import { TrashIcon } from 'lucide-react';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { Button } from '@client/components/ui/button';
import { Separator } from '@client/components/ui/separator';
import { trpc } from '@client/lib/trpc';
import { useBooking } from '@client/features/booking/hooks/use-booking';

type Props = CommonPropsForModal & {
  date: string;
  awayDatesType: UserWorkStatusType | undefined;
  conflictWith?: UserWorkStatusType | undefined;
  conflictOnRemove?: () => void;
};

const DefaultModal = (props: Omit<Props, 'conflictWith' | 'conflictOnRemove'>) => {
  const { onDone, onClose, date, awayDatesType: _awayDatesType, ...restForModal } = props;

  const { createAwayDates, updateAwayDates, removeAwayDates } = useBooking();

  const workStatusGetAwayDatesForDateQuery = trpc.workStatus.getAwayDatesForDate.useQuery({
    date,
  });

  const { data: awayDates } = workStatusGetAwayDatesForDateQuery.data ?? {};

  if (!_awayDatesType && !workStatusGetAwayDatesForDateQuery.isLoading && !awayDates) {
    throw new Error('awayDatesType or awayDates must be provided');
  }

  // we're either editing an existing away dates or creating new ones
  const awayDatesType = useMemo<UserWorkStatusType>(() => {
    return awayDates?.type ?? _awayDatesType!;
  }, [_awayDatesType, awayDates]);

  const handleSubmit = useCallback(
    async (values: AwayDatesFormValues) => {
      let success = false;
      if (awayDates?.id) {
        success = await updateAwayDates({
          id: awayDates.id,
          note: values.note,
          dates: {
            from: values.dates!.from!,
            to: values.dates!.to ?? values.dates!.from!,
          },
        });
      } else {
        success = await createAwayDates({
          type: awayDatesType,
          note: values.note,
          dates: {
            from: values.dates!.from!,
            to: values.dates!.to,
          },
        });
      }
      if (success) {
        onDone?.();
        onClose?.();
      }
    },
    [awayDates?.id, awayDatesType, onDone, onClose]
  );

  const handleRemove = useCallback(async () => {
    if (awayDates) {
      const success = await removeAwayDates({ id: awayDates.id, type: awayDates.type });
      if (success) {
        onDone?.();
        onClose?.();
      }
    }
  }, [awayDates, onDone, onClose]);

  const profile = useMemo(() => {
    return {
      ...AWAY_DATES_DESCRIPTION_MAPPING[awayDatesType as keyof typeof AWAY_DATES_DESCRIPTION_MAPPING],
      emoji: new UserWorkStatusTypeHelper(awayDatesType).profile.emoji,
    };
  }, [awayDatesType]);

  const commonModalProps: ModalLayoutProps = useMemo(
    () => ({
      title: `${titleCase(profile.description)}  ${profile.emoji}`,
      loading: workStatusGetAwayDatesForDateQuery.isLoading,
      submitFormId: AwayDatesForm.id,
      submitText: awayDates ? 'Update' : 'Submit',
      closeText: props.nested ? 'Back' : 'Cancel',
      onClose,
      ...restForModal,
    }),
    [profile, awayDates, restForModal]
  );

  const data = useMemo<Partial<AwayDatesFormValues>>(() => {
    if (awayDates) {
      return {
        note: awayDates.note ?? undefined,
        dates: {
          from: parse(awayDates.fromDate, yyyy_MM_dd, new Date()),
          to: parse(awayDates.toDate, yyyy_MM_dd, new Date()),
        },
      };
    }
    return {
      dates: {
        from: date ? parse(date, yyyy_MM_dd, new Date()) : new Date(),
        to: undefined,
      },
    };
  }, [date, awayDates]);

  return (
    <ModalLayout {...commonModalProps}>
      <AwayDatesForm onSubmit={handleSubmit} data={data} type={awayDatesType} />
      {awayDates && (
        <>
          <Separator className="my-4" />
          <ButtonGroup className="mt-4">
            <Button variant="destructive" size="sm" onClick={handleRemove} tabIndex={-1}>
              <TrashIcon className="h-4 w-4 mr-2" />
              {titleCase(profile.cancelText)}
            </Button>
          </ButtonGroup>
        </>
      )}
    </ModalLayout>
  );
};

export const AwayDatesModal = (props: Props) => {
  const { conflictWith, conflictOnRemove, ...rest } = props;
  if (conflictWith) {
    return null;
    // return <ConflictModal conflictWith={conflictWith} conflictOnRemove={conflictOnRemove} {...rest} />;
  } else {
    return <DefaultModal {...rest} />;
  }
};


// const ConflictModal = (props: Props & { conflictWith: UserWorkStatusType }) => {
//   const {
//     onDone,
//     date,
//     awayDates,
//     type: _type,
//     conflictWith,
//     conflictOnRemove,
//     onRemove,
//     onUpdate,
//     ...restForModal
//   } = props;

//   const existingHelper = useMemo(() => {
//     return new UserWorkStatusTypeHelper(awayDates!.type);
//   }, [awayDates]);
//   const desiredHelper = useMemo(() => {
//     return new UserWorkStatusTypeHelper(conflictWith);
//   }, [conflictWith]);

//   const profile = useMemo(() => {
//     return {
//       ...AWAY_DATES_DESCRIPTION_MAPPING[awayDates!.type as keyof typeof AWAY_DATES_DESCRIPTION_MAPPING],
//       emoji: new UserWorkStatusTypeHelper(awayDates!.type).profile.emoji,
//     };
//   }, [awayDates]);

//   return (
//     <ModalLayout
//       title={`${titleCase(profile.cancelText)}?`}
//       loading={false}
//       onSubmit={conflictOnRemove}
//       submitText="Confirm"
//       closeText="Close"
//       {...restForModal}>
//       <div>
//         <QuoteBlock className="italic">
//           You are currently away on this date. Cancel {existingHelper.profile.name} and change to{' '}
//           {desiredHelper.profile.name} instead?
//         </QuoteBlock>
//       </div>
//       <div className="flex items-center gap-2 mt-4">
//         <Emoji>{existingHelper.profile.emoji}</Emoji>
//         <TypographyP className="ml-2 line-through">{`${existingHelper.profile.name}  (${formatAwayDatesDate(awayDates!)})`}</TypographyP>
//         <ArrowRightIcon className="h-4 w-4" />
//         <Emoji>{desiredHelper.profile.emoji}</Emoji>
//         <TypographyP className="font-bold">{desiredHelper.profile.name}</TypographyP>
//       </div>
//     </ModalLayout>
//   );
// };